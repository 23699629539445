import { apiError, UPDATE_USER } from ".";

export async function doUpdateUser(id, email, user, role, password) {
  try {
    const body = { email, user, role, password };

    const response = await fetch(UPDATE_USER + "/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.token,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doUpdateUser");
  }
}
