const setSymbolColor = (layer, category) => {
  for (var i = 0, len = layer.symbols.length; i < len; i++)
    if (layer.symbols[i].category === category) return layer.symbols[i].color;
  return "#FFFFFF";
};

export function jsonStyle(feature, layer) {
  const category = feature.properties[layer.markerField]
  var color = layer.color;

  if (layer.markerType === "symbols") {
    color = setSymbolColor(layer, category);
  }

  return {
    color: color,
    weight: layer.weight,
    opacity: layer.opacity,
    fill: layer.fill,
    lineJoin: layer.lineJoin,
  };
}
