import React, { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { UserContext } from "../../components/UserContext";
import PageHeader from "../../components/PageHeader";
import UserManPage from "../../../auth/pages/UserManPage";

import Mapplet from "../../../mapplet";
// import CovidMap from "../../../_covidmap";

const Home = () => {
  // useEffect(() => {
  //   console.log(' ----> Home ----> (mounted)')   
  //   return () => {
  //     console.log(" ----> Home ----> (unmounted)");
  //   };
  // }, [])

  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const dayPassed = () => {
    const releaseDate = new Date("2022-02-06");
    const timeDifference = new Date() - releaseDate;
    const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return number_of_days;
  };

  const loggedIn = () => {
    const number_of_days = dayPassed();
    return (
      <div>
        <PageHeader title={t("welcome_message")} />
        <p>{t("days_since_release", { number_of_days })}</p>
      </div>
    );
  };

  //////////////////////////////////////////////////////////////////////

  return (
    <div>
      {!user.isLoggedIn ? (
        <Mapplet />
      ) : user.role === "admin" ? (
        <UserManPage />
      ) : (
        loggedIn()
      )}
    </div>
  );
};

export default Home;
