var borderWidth = 1.5;

export function getSymbol(symbols, category) {
    for (var i = 0, len = symbols.length; i < len; i++) 
    if (symbols[i].category === category) 
      return symbols[i]
  return symbols[0] // no category found
}

export function SVGSquare(scale=1, translateX=0, translateY=0, color='white', borderColor='black', opacity=1) {
	// const borderWidth = 1
	let height = 22
	let width = 22

	height = (height + 2 * borderWidth) * scale
	width = (width + 2 * borderWidth) * scale

	return ({
		svg: '<svg height="'+ height + '" width="' + width +'">' +
						'<polygon points="2,2 2,18 18,18 18,2"' + 
						'style="fill:' + color + ';' +
						'stroke:' + borderColor + ';stroke-width:' + borderWidth + ';fill-rule:nonzero;' + 
						'fill-opacity:' + opacity + '"' + 
						'transform="scale(' + scale + ') ' +
						'translate(' + translateX + ',' + translateY + ')' + 
						'"/>' +
					'</svg>', 
		height: height, 
		width: width
	})
}
  
export function SVGCircle(scale=1, translateX=0, translateY=0, color='white', borderColor='black', opacity=1) {
	const radius = 7
	// const borderWidth = 1
	const cx = radius + borderWidth
	const cy = cx
	let height =  2 * cx
	let width = height

	height = (height + 2 * borderWidth) * scale
	width = (width + 2 * borderWidth) * scale
	
	return ({
		svg: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="' + height + '" width="' + width + '">' + 
						'<circle cx="' + cx + '" cy="' + cy + '" r="' + radius + '"' + 
						'fill="' + color + '"fill-opacity="' + opacity + '"' +
						'stroke="' + borderColor + '" stroke-width="' + borderWidth + '"' + 
						'transform="scale(' + scale + ') ' +
						'translate(' + translateX + ',' + translateY + ')' + 
						'"/>' + 
					'</svg>',
		height: height,
		width: width
	})
}
  
export function SVGRect(w, h, scale=1, translateX=0, translateY=0, color='white', borderColor='black', opacity=1) {
	// const borderWidth = 1
	const height = (1.1 * h + 2 * borderWidth) * scale
	const width = (1.1 * w + 2 * borderWidth) * scale
	const dh = 2 + h
	const dw = 2 + w
	
	return ({
		svg: '<svg height="' + height + '" width="' + width + '">' + 
						'<polygon points="2,2 2,' + dh + ' ' + dw + ',' + dh + ' ' + dw + ',2"' +
						'fill="' + color + '"fill-opacity="' + opacity + '"' + 
						'stroke="' + borderColor + '" stroke-width="' + borderWidth + '"' +
						'transform="scale(' + scale + ') ' +
						'translate(' + translateX + ',' + translateY + ')' + 
						'"/>' +
					'</svg>',
		height: height,
		width: width
	})
}

export function SVGStar(scale=1, translateX=0, translateY=0, color='white', borderColor='black', opacity=1) {
	// const borderWidth = 1
	const height = 20 * scale
	const width = 20 * scale


	return ({
		svg: '<svg height="'+ + height + '" width="' + width +'">' +
					'<polygon points="10,1 7.7,8 1,8 6.3,12 4,19 10,14.7 16,19 13.7,12 19,8 12.3,8"' + 
					'style="fill:' + color + ';' +
					'stroke:' + borderColor + ';stroke-width:' + borderWidth + ';fill-rule:nonzero;' + 
					'fill-opacity:' + opacity + '"' + 
					'transform="scale(' + scale + ') ' +
					'translate(' + translateX + ',' + translateY + ')' + 
					'"/>' +
				'</svg>',
		width: width,
		height: height
	})
}
  
export function SVGDiamond(scale=1, translateX=0, translateY=0, color='white', borderColor='black', opacity=1) {
	// const borderWidth = 1
	const height = 20 * scale
	const width = 20 * scale

	return ({
		svg: '<svg height="'+ + height + '" width="' + width +'">' +
					'<polygon points="10,1 1,10 10,19 19,10"' + 
					'style="fill:' + color + ';' +
					'stroke:' + borderColor + ';stroke-width:' + borderWidth + ';fill-rule:nonzero;' + 
					'fill-opacity:' + opacity + '"' + 
					'transform="scale(' + scale + ') ' +
					'translate(' + translateX + ',' + translateY + ')' + 
					'"/>' +
				'</svg>',
		width: width,
		height: height 
	})
}