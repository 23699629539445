/*
const MAPPLET_API =
  process.env.NODE_ENV === "production"
    ? "mapplets"
    : "https://localhost:5000/mapplets";
*/
// console.log("process.env:", process.env);

//const MAPPLET_API = "localhost:5000/mapplets";

/* const MAPPLET_API =
  process.env.NODE_ENV === "production"
    ? "localhost:5000/mapplets"
    : "http://localhost:5000/mapplets";
*/

const MAPPLET_API = "https://gistarc.unicampania.it:5000/mapplets";

export const GET_INIT_FILES = `${MAPPLET_API}/get-init-files`;
export const GET_INIT_FILE = `${MAPPLET_API}/get-init-file`;
export const GET_JSON = `${MAPPLET_API}/get-json`;
export const GET_DBJSON = `${MAPPLET_API}/get-dbjson`;
