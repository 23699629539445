import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet';
import {useEffect} from 'react'

export function Watermark(props) {
    const context = useLeafletContext()

    L.Control.Watermark = L.Control.extend({
        onAdd: function (map) {
            var img = L.DomUtil.create('img');

            img.src = '/assets/images/uv-logo.png';
            img.style.width = '35px';
            img.style.background = 'rgb(255, 255, 255, 0.5)';
            img.style.borderRadius = '4px';
            img.style.padding = '4px';
            img.style.border = '2px solid rgba(172, 172, 172, 0.692)'

            return img;
        },

        onRemove: function (map) {
            // Nothing to do here
        }
    });

    L.control.watermark = function (opts) {
        return new L.Control.Watermark(opts);
    }

    useEffect(() => {
        const container = context.layerContainer || context.map

        const control = L.control.watermark({ position: props.position || "bottomleft" })
        container.addControl(control)

        return () => {
            container.removeControl(control)
        }
    })

    return null
}