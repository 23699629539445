import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n, { t } from "i18next";
// import HttpApi from "i18next-http-backend";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, useTranslation } from "react-i18next";

import i18next from "i18next";

import cookies from "js-cookie";

import FlagDropdown from "./FlagDropdown";

// i18n initialization
const fallbackLanguageCode = "it";

i18n
  // .use(HttpApi)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next); // passes i18n down to react-i18next

const i18nOptions = {
  backend: {
    //translation file path
    loadPath: "/assets/locales/{{lng}}/translation.json",
    //* loadPath: "/assets/locales/{{ns}}/{{lng}}.json",
  },

  fallbackLng: { fallbackLanguageCode },

  //disabled in production
  //debug: true,

  //can have multiple namespaces, in can you want to divide a huge
  //translation in smaller pieces and load them on demand
  //* ns: ["common", "auth", "mapplet"],
  supportedLngs: ["en", "it"],

  interpolation: {
    escapeValue: false,
    formatSeparator: false,
    crossDomain: true,
  },

  react: {
    useSupense: true,
  },

  detection: {
    order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
    caches: ["cookie"],
  },
};

i18n.init(i18nOptions, () => console.log("app_title: " + t("app_title")));

// languages
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "en",
  },
  {
    code: "it",
    name: "Italiano",
    country_code: "it",
  },
];

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);

  var languageCode = cookies.get("i18next") || { fallbackLanguageCode };
  if (languageCode.length > 2) {
    languageCode = fallbackLanguageCode;
  }
  const currLanguage = languages.find((l) => l.code === languageCode);

  const handleLanguageChange = () => {
    i18n.changeLanguage(selected);
    window.location.assign("/"); //website reload needed when changing language
  };

  useEffect(() => {
    // console.log("--------------------------------------LanguageReference");
    // console.log("LanguageSelector -> selected:", selected);
    if (selected) handleLanguageChange();
    // document.title = t("app_title");
  }, [selected]);

  return (
    <FlagDropdown
      selected={currLanguage.code}
      setSelected={setSelected}
      languages={languages}
    />
  );
};
