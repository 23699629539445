// MapFilter.js
// -------------------------------
// functional version - 2020.05.28 (MappletFilter.js)
// revision started - 2022.03.15

import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";

import { useMap } from "react-leaflet";
import { t } from "i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { getLayerIdx, isVisibleLayer } from "../../utils/helpers";
import { MapContext } from "../MapContext";

import "./style.css";

function LayerFilterHeader({display, values, keyValue, handleCollapse}) {
  return (
    <div style={{display:"flex", alignItems:"center", paddingLeft:"0px"}}>
      {values
      ? <label className="btn" style={{height:"20px", width:"30px", paddingTop:"3px"}} onClick={handleCollapse}>
        <FontAwesomeIcon icon={display === "none" ? faChevronRight : faChevronDown} />
      </label> 
      : <span style={{paddingLeft: "26px"}}></span>}
      &nbsp;&nbsp;
      <span id={"key-"+keyValue} style={{paddingTop:"4px", fontSize:"medium"}}>{t(keyValue)}</span>
    </div>
  )
}


function LayerFilterSelectedValues({values, filterValues}) {
  // console.log("LayerFilterSelectedValues -> filterValues:", filterValues)

  if (filterValues[0] !== ">>>empty<<<") {

    var allSelected = true;
    if (filterValues[0] && filterValues[0] !== "") {
      values.map((value) => {
        if (filterValues.indexOf(value) < 0) {
          allSelected = false;
          return;
        }
      })
    }

    if (allSelected || (filterValues[0] && filterValues[0] === "")) {
      return (
        <div style={{paddingLeft:"37px"}}>
        <b>{t("Selected")}:</b>&nbsp;{t("all")}
        </div>
      )
    }

    if (filterValues[0] && filterValues[0] !== "") {
      return (
        <div style={{paddingLeft:"37px"}}>
          <b>{t("Selected")}:</b>&nbsp;
          {filterValues.map((filterValue, index) => {
            if (index === 0)
              return (<span key={index}>{filterValue}</span>)
            else 
              return (<span key={index}>, {filterValue}</span>)
          })}
        </div>
      )
    }
    else
      return (
        <div style={{paddingLeft:"37px"}}>
        <b>{t("Selected")}:</b>&nbsp;{t("all")}
        </div>
      )
    }

  else
    return null;
}


function LayerFilterValues({display, layer, values, onClick, onClickAll, keyValue}) {
  return (
    <div style={{
      display:display, 
      borderTop: "solid 1px rgba(255,255,255,0.8)", 
      marginLeft:"6px",
      paddingTop: "10px", 
      paddingLeft: "31px", 
      backgroundColor:"rgba(255,255,255,0.0",
    }}>
      <form name={"form-" + layer.name + "-" + keyValue}>
      <div style={{paddingBottom: "5px"}} key={layer.name+";"+keyValue+";all"}>
          <input
            type="checkbox"
            defaultChecked
            id={layer.name+";"+keyValue+";all"}
            onClick={onClickAll}
          />
          &nbsp;&nbsp;
          <span style={{fontWeight:600}}>
            {t("select-all")}
            <br />
          </span>
        </div>

        {/* normal values */}
        {values
          ? values.map((value) => {
              return (
                <div key={layer.name + ";" + keyValue + ";" + value}>
                  <input
                    type="checkbox"
                    defaultChecked
                    id={layer.name + ";" + keyValue + ";" + value}
                    onClick={onClick}
                  />
                  <span>&nbsp;&nbsp;{value}</span>
                </div>
              );
            })
          : null}
      </form>
    </div>
  )
} 


function LayerFilter({layer, keyValue, keyIdx, values, filterValues, setJsonFilter }) {
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    //console.log("LayerFilter-useEffect[filterValues] ->", layer.name, keyValue, filterValues);
    //console.log("LayerFilter-useEffect[filterValues] -> values:", values)

    const allID = layer.name+";"+keyValue+";all";
    const allElement = document.getElementById(allID);

    if (filterValues[0] === "") {
      // all elements checked, including all
      values.map((value) => {
        const inputID = layer.name+";"+keyValue+";"+value;
        const inputElement = document.getElementById(inputID);
        if (inputElement)
          inputElement.checked = true;
      })
      allElement.checked = true;
      filterValues[0] = "";
    } 
    else if (filterValues[0] === ">>>empty<<<") {
      // all elements unchecked
      values.map((value) => {
        const inputID = layer.name+";"+keyValue+";"+value;
        const inputElement = document.getElementById(inputID);
        if (inputElement)
          inputElement.checked = false;
      })
      allElement.checked = false;
    } 
    else {
      var allSelected = true;
      values.map((value) => {
        const inputID = layer.name+";"+keyValue+";"+value;
        const inputElement = document.getElementById(inputID);
        if (inputElement) {
          if (filterValues.indexOf(value) >= 0) {
            //the value must be checked
            inputElement.checked = true;
          } else {
            //the value must be unchecked
            inputElement.checked = false;
            allSelected = false;
          }        
        }
      })
      allElement.checked = allSelected;
    }

  }, [filterValues])

  const handleCollapse = (e) => {
    e.preventDefault();
    setDisplay(display === "none" ? "block" : "none");
  };

  const onClick = (e) => {
    //console.log('onClick')

    const form = e.target.parentNode.parentNode;
    const elements = document.forms[form.name].getElementsByTagName("input");
    const layerName = elements[0].id.split(";")[0];

    const values = [];

    //console.log("MapFilter-onClick -> layerName keyValue elements:", layerName, keyValue, elements)

    var allFalse = true;
    for (var i = 1, len = elements.length; i < len; i++)
      if (elements[i].checked) {
        values.push(elements[i].id.split(";")[2]);
        allFalse = false;
      }
      
    //console.log("MapFilter-onClick -> values:", values)

    if (allFalse)
      values.push(">>>empty<<<")

    setJsonFilter(layerName, values, keyIdx);
  };

  const onClickAll = (e) => {
    //console.log('onClickAll')

    const form = e.target.parentNode.parentNode;
    const elements = document.forms[form.name].getElementsByTagName("input");
    const layerName = elements[0].id.split(";")[0];
  
    const values = [];

    //console.log("MapFilter-onClickAll -> layerName keyValue elements:", layerName, keyValue, elements)

    if (elements[0].checked) {
      for (var i = 1, len = elements.length; i < len; i++)
        elements[i].checked = true;
    } else {
      for (var i = 1, len = elements.length; i < len; i++)
        elements[i].checked = false;
      values.push(">>>empty<<<"); // tricky setting of values to get no features:
                                  // the '>>>empty<<<' is not used for any field
    }

    //console.log("MapFilter-onClickAll -> values:", values)

    // for (var i = 1, len = elements.length; i < len; i++)
    //   if (elements[i].checked)
    //     values.push(elements[i].id.split(";")[1]);

    setJsonFilter(layerName, values, keyIdx);
  };

  return(
    <div 
      className="form-group top-margin-small" 
      style={{
        width: "99.3%", 
        backgroundColor: "rgba(255,255,255,0.4",
        borderRadius: "4px",
        border: "solid 1px lightgray"
        }}>
      <LayerFilterHeader
        display={display}
        values={values}
        layer={layer}
        keyValue={keyValue}
        handleCollapse={handleCollapse}
      />
      <LayerFilterSelectedValues values={values} filterValues={filterValues} />
      <LayerFilterValues 
        display={display} 
        layer={layer} 
        values={values}
        keyValue={keyValue}
        onClick={onClick} 
        onClickAll={onClickAll} 
      /> 
    </div>   
  )
}


const MapFilter = forwardRef(({ setJsonFilter }, ref) => { 
  const map = useMap();

  const { initFile, layersControlRef, datasets, filterValues } = useContext(MapContext);
  const layers = initFile.jsonLayers

  const [{ selectedLayer, filterLayers, keys }, setState] = useState({
    selectedLayer: "",
    filterLayers: [],
    keys: [],    
  });

  function setSelectedLayer(layerName) {
    setState((prevState) => ({
      ...prevState,
      selectedLayer: layerName,
    }));
  }
  const [filterRefresh, setFilterRefresh] = useState(false);

  useImperativeHandle(ref, () => ({
    setRefresh() {
      // used by Map component when an overlay is added to/removed
      // from the map to signal to MapFilter it must re-render
      // with new layers
      setFilterRefresh(true);
    },
  }));

  function changeLayer(e) {
    setSelectedLayer(e.target.value);
  }

  function filterInit() {
    let _selectedLayer = "";
    let _filterLayers = [];
    let _keys = [];

    layers.forEach((layer) => {
      const layerIdx = getLayerIdx(layers, layer.name);
      const keyFields = layers[layerIdx].keyFields;
      if (_selectedLayer === "" && keyFields && keyFields.length > 0) 
        _selectedLayer = layer.name;
      _filterLayers.push(layer.name);
      _keys.push(keyFields);
    });

    //create the initial state
    setState({
      selectedLayer: _selectedLayer,
      filterLayers: _filterLayers,
      keys: _keys,
    });    
  }

  useEffect(() => {
    filterInit();
  }, [])

  useEffect(() => {
    filterInit();
    setFilterRefresh(false);
  }, [filterRefresh]);

  function layerSelector(selectedLayer) {
    var changeSelectedLayer = false;
    return (
      <div className="form-group top-margin-small">
        <label className="card-selector-label"></label>
        <select
          id="layers"
          className="card-selector form-control card-header"
          onChange={(e) => changeLayer(e)}
        >
          {filterLayers.map((layerName, i) => {
            if (isVisibleLayer(map, layersControlRef, layerName)) {
              if (changeSelectedLayer) {
                setSelectedLayer(layerName);
                changeSelectedLayer = false;
              }
              return <option key={i}>{layerName}</option>;
            }
            else {
              if (selectedLayer === layerName) {
                changeSelectedLayer = true;
              }
            }
          })}
        </select>
      </div>
    );
  }

  function layerFilters(selectedLayer, keyFields, setJsonFilter) {
    const layerIdx = getLayerIdx(initFile.jsonLayers, selectedLayer);
    const filterLayerIdx = filterLayers.indexOf(selectedLayer);
    
    if (filterLayerIdx >= 0) {
      const keyValues = keyFields[filterLayerIdx]
      return (
        <div>
          {keyValues.map((keyValue, keyIdx) => {      
            return (
              <LayerFilter key={keyIdx}
                layer={initFile.jsonLayers[layerIdx]}
                keyValue={keyValue}
                keyIdx={keyIdx}              
                values={datasets[layerIdx][keyIdx]}
                filterValues={filterValues[layerIdx][keyIdx]}
                setJsonFilter={setJsonFilter}
              />      
            )})
          }
        </div>
      );
    } else {
      return null;
    }
  }

  var visibleLayers = 0;
  for (let i = 0; i < filterLayers.length; i++)
    if (isVisibleLayer(map, layersControlRef, filterLayers[i]))
      visibleLayers++;
  
  if (visibleLayers > 0)
    return (
      <section className="sidebar-table">
        <div className="top-margin-small">
          {layerSelector(selectedLayer)}
          {layerFilters(selectedLayer, keys, setJsonFilter)}
        </div>
      </section>
    );
  else
    return (
      <section className="sidebar-table">
      <div className="top-margin-small">
        <span style={{fontSize:"medium", fontWeight:"bolder"}}>{t("No active layers")}.</span>
      </div>
    </section>
      
    );
});

export default MapFilter;
