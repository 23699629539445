const sortJsonLayers = (a, b, jsonLayers, initFile) => {
  const getIndex = (a) => {
    for (let i = 0; i < jsonLayers.length; i++) {
      if (initFile.jsonLayers[i].fname === a.fname) {
        return i;
      }
    }
    return -1;
  };

  const a_i = getIndex(a);
  const b_i = getIndex(b);
  return a_i > b_i ? 1 : -1;
};

export default sortJsonLayers;
