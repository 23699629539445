import { apiError, DELETE_USER } from ".";

export async function doDeleteUser(id) {
  try {
    const response = await fetch(DELETE_USER + "/" + id, {
      method: "DELETE",
      headers: { token: localStorage.token },
    });
    return await response.json();

  } catch (err) {
    return apiError(err.message, err.stack, "doDeleteUser")
  }
}
