import { apiError, IS_GOOD_TOKEN } from ".";

export async function doIsGoodToken() {
  try {
    const response = await fetch(IS_GOOD_TOKEN, {
      method: "POST",
      headers: { token: localStorage.token },
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doIsGoodToken");
  }
}
