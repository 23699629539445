import Home from "../main/pages/Home";
import UserManPage from "../auth/pages/UserManPage";

const routes = [
  ////// main pages //////
  {
    key: "home",
    path: "/",
    navText: "Home",
    isNav: true,
    component: Home,
  }, 
  ////// auth pages //////
  {
    key: "user-man",
    path: "/user-man",
    navText: "UserMan",
    isNav: false,
    component: UserManPage,
  },
];

export default routes;
