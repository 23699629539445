import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";

// components
import ErrorBoundary from "./main/components/ErrorBoundary";
import Main from "./main";

// get routes from config
import routes from "./config/routes";
import UserContextProvider from "./main/components/UserContext";

const loadingMarkup = (
  <div className="spinner-grow" role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={loadingMarkup}>
      <Router basename={process.env.PUBLIC_URL}>
        <UserContextProvider>
            <Main routes={routes} />
        </UserContextProvider>
      </Router>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById("root")
);
