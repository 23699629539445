import { Modal } from "react-bootstrap";

function ConfirmModal({ title, message, text, show, setShow, confirm, yes, no }) {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{message} '{text}'?</Modal.Body>
    <Modal.Footer>
      <button className="btn btn-danger" onClick={() => confirm(true)}>{yes}</button>
      <button className="btn btn-warning" onClick={() => confirm(false)}>{no}</button>
    </Modal.Footer>
  </Modal>
  );
}

export default ConfirmModal;
