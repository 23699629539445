import React, { useEffect, useContext } from "react";

import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { popupMsg } from "../utils/popupMsg";

import { doLogout } from "../auth/api/doLogout";
import { doReadUserFromToken } from "../auth/api/doReadUserFromToken";

import { UserInfo } from "./components/UserInfo";

// language
import { LanguageSelector } from "../config/languages";

// context
import { UserContext } from "./components/UserContext";

// api
import { doIsGoodToken } from "../auth/api/doIsGoodToken";

const Main = ({ routes }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    try {
      async function isLoggedIn() {
        if (localStorage.token) {
          const isGoodToken = await doIsGoodToken();

          if (isGoodToken.success) {
            const response = await doReadUserFromToken();
            if (response.success) {
              setUser({
                isLoggedIn: true,
                name: response.user.user_name,
                role: response.user.user_role,
              });
            }
          } else {
            popupMsg("Main", t("invalid_session"), "Error");
            doLogout(history, setUser);
          }
        } else {
          doLogout(history, setUser);
        }
      }
      isLoggedIn();
    } catch (error) {
      popupMsg("Main", t("error_logging_in"), "Error");
      doLogout(history, setUser);
    }
  }, [history, setUser, t]);

  return (
    <div style={{ margin: 20, display: "flex", flexDirection: "column" }}>
      <nav>
        {routes
          .filter((r) => r.isNav)
          .map(({ key, path, navText }, navIndex) => (
            <NavLink
              style={{ paddingTop: "10px", marginLeft: navIndex > 0 ? 12 : 0 }}
              key={`navlink-${key}`}
              to={path}
            >
              {navText}
            </NavLink>
          ))}
        <span style={{ float: "right" }}>
          <div className="d-flex justify-content-end">
            <UserInfo />
            <LanguageSelector />
          </div>
        </span>
      </nav>
      <React.StrictMode>
        <Switch>
          {routes.map(({ key, path, component }) => (
            <Route
              exact
              key={`route-${key}`}
              path={path}
              component={component}
            />
          ))}
          <Redirect from="*" to="/" />
        </Switch>
      </React.StrictMode>
    </div>
  );
};

Main.defaultProps = {
  routes: [],
};

export default Main;
