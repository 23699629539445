import { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";

import { UserContext } from "../../components/UserContext";

const LoginForm = () => {
  const { submitLogin } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div>
      <Form onSubmit={(e)=> submitLogin(e, email, password)}>
        <Form.Group>
          <Form.Control
            style={{ marginBottom: 10 }}
            className="form-control"
            type="email"
            name="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="on"
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            style={{ marginBottom: 10 }}
            className="form-control"
            type="password"
            name="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="on"
          ></Form.Control>
        </Form.Group>
        <Button variant="success" type="submit" block>
          Login
        </Button>
      </Form>
    </div>
  );
};

export default LoginForm;
