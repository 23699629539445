import { apiError } from "../../utils/apiError";
import { GET_DBJSON } from ".";

export async function doGetDbJSON(table, minus) {
	var url = GET_DBJSON + "?tbl=" + table;
  
  url += (minus) ? "&minus="+minus : "";

	// if (minus && minus.length > 0) {
	// 	url += "&minus=";
	// 	for (let i = 0, len = minus.length; i < len; i++) {
	// 		url += minus[i];
	// 		url += (i < len - 1) ? "," : "";
	// 	}
	// }


  console.log(url)
  try {
    const response = await fetch(url, {
      method: "GET",
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doGetDbJSON");
  }
}
