import React from "react";
import Map from "./components/Map";
import MapContextProvider from "./components/MapContext";
import "./style.css";

const Mapplet = () => {
  var mappletInitFile = null;
  var urlMapplet = false;

  const url = new URL(window.location.href);
  mappletInitFile = url.searchParams.get("mapplet");
  if (mappletInitFile === null) {
    mappletInitFile = "default.json";
  } else {
    urlMapplet = true;
  }

  return (
    <React.StrictMode>
      <MapContextProvider
        mappletInitFile={mappletInitFile}
        urlMapplet={urlMapplet}
      >
        <Map />
      </MapContextProvider>
    </React.StrictMode>
  );
};

export default Mapplet;
