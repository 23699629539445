import { LayersControl, TileLayer } from "react-leaflet";

const addBaseLayer = (layer) => {
  return (
    <LayersControl.BaseLayer
      key={layer.name}
      name={layer.name}
      checked={layer.checked}
    >
      <TileLayer
        url={layer.url}
        attribution={layer.attribution}
        minZoom={layer.minZoom}
        maxZoom={layer.maxZoom}
        bounds={layer.bounds}
        format={layer.format}
        time={layer.time}
        tilematrixset={layer.tilematrixset}
        //subdomains={layer.subdomains}
        ext={layer.ext}
      />
    </LayersControl.BaseLayer>
  );
};

export default addBaseLayer;
