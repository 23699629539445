import React from "react";

const PageHeader = (props) => {
  const { title } = props;
  return (
    <div>
      <h1 className="font-weight display-1">{title}</h1>
    </div>
  );
};

export default PageHeader;
