export function jsonFilter(feature, keyFields, layerFilterValues) {
  if (!keyFields)
    //no keyFields defined (in init file - strange condition...) => the feature
    return true;

  if (!layerFilterValues)
    //no filter values specified (strange condition...) => show the feature
    return true;

  //keyFields and layerFilterValues are defined => check filter values for each field
  var retStatus = true;

  //some key fields are defined
  //---------------------------
  //=> check their values against the corresponding filter values
  //   set by MapFilter, and hide the feature if:
  //   a) the corresponding feature's field is not defined (null)
  //   b) the feature's field is not matching against 
  //      one of the filter values

  //for each key field
  for (var i = 0; i < keyFields.length; i++) {
    //current field: keyFields[i]

    var fieldStatus;

    if (layerFilterValues[i].length === 0) 
      //no filter values set => the feature could be shown,
      //if the other conditions for the other fields are met
      fieldStatus = true;

    else {
      //some filter values set...

      //initialize to: hide the feature
      fieldStatus = false;

      //for each filter value
      for (var j = 0; j < layerFilterValues[i].length; j++) {
        
        //a) => check if the corresponding feature's field is null
        if (feature.properties[keyFields[i]] === null) {
          //null => show the feature anyway (TODO: no null feature's field shoud exist...)
          fieldStatus = true;

        } else {
          //the feature's field is not null

          //check if the current filter value is an empty string
          //NOTE: it can happen only if not any filter value was 
          //      set in MapFilter for the current field
          if (layerFilterValues[i][j] === "")
            //the filter value is empty => nothing to check => show the feature
            fieldStatus = true;

          else {

            //the filter value is a non-empty string 
            //b) => check if the feature's field matches the corresponding filter value
              if (feature.properties[keyFields[i]].toString() === layerFilterValues[i][j]) {
                //matching => show the feature
                fieldStatus = true;
                break;
              }
          } 
        }       
      }
      retStatus = retStatus && fieldStatus;            
    }
    retStatus = retStatus && fieldStatus;
  }
  return retStatus;
}
