import { LayersControl, WMSTileLayer } from "react-leaflet";

const addWmsLayer = (layer) => {
  return (
    <LayersControl.Overlay
      key={layer.name}
      name={layer.name}
      checked={layer.checked}
    >
      <WMSTileLayer
        url={layer.url}
        layers={layer.layers}
        opacity={layer.opacity}

      />
    </LayersControl.Overlay>
  );
};

export default addWmsLayer;
