import { apiError } from "../../utils/apiError";
import { GET_INIT_FILE } from ".";

export async function doGetInitFile(id) {
  try {
    const response = await fetch(GET_INIT_FILE + "/" + id, {
      method: "GET",
    });
    const json = await response.json();
    //console.log("doGetInitFile:", json);
    return json;
    // if (response.ok) {
    //   return await response.json();
    // } else {
    //   if (!response.success) {
    //     const error = await response.json()
    //     return error
    //   } else {
    //     return apiError("API Error", "", "doGetInitFile");
    //   }
    // }
  } catch (err) {
    return apiError(err.message, err.stack, "doGetInitFile");
  }
}
