import L from "leaflet";
import "./style.css";

function notifyMarker(lat, lng) {
  const notifyIcon = L.divIcon({
    className: "notify-icon",
    iconSize: [25, 25],
    html: "<span></span>",
  });
  const marker = L.marker([lat, lng], { icon: notifyIcon });
  // console.log("Map -> notifyMarker:", marker);
  return marker;
}

export default notifyMarker;
