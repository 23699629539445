import L from "leaflet";
import notifyMarker from "./notifyMarker";

function setLocation(map, lat, lng, time) {
  map.setView({ lat: lat, lng: lng }, 17);

  if (map.hasLayer(highlight)) map.removeLayer(highlight);
  
	var highlight = L.geoJSON(null);
  map.addLayer(highlight);

  highlight.addLayer(notifyMarker(lat, lng));
  const removeHighlight = () => {
    if (map.hasLayer(highlight)) map.removeLayer(highlight);
  };
	
  setTimeout(removeHighlight, time);
}

export default setLocation;
