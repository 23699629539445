import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../../main/components/UserContext";

import { UserManContext } from "./UserManContext";
import UserManContextProvider from "./UserManContext";

import DataTable from "../../../utils/DataTable";

const UserMan = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <div>
      <UserManContextProvider>
        {(user.role === 'admin') && <DataTable
          title={t("users")}
          itemName={t("user")}
          context={UserManContext}
          pageSize={4}
          alertDuration={1500}
        />}
      </UserManContextProvider>
    </div>
  );
};

export default UserMan;
