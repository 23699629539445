import { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { UserManContext } from "../UserManContext";

import EditUserForm from "../EditUserForm";
import ConfirmModal from "../../../../utils/ConfirmModal";
import EditModal from "../../../../utils/EditModal";
import { popupMsg } from "../../../../utils/popupMsg";

import "./style.css"

const User = ({ item }) => {
  const { t } = useTranslation();
  const { deleteUser } = useContext(UserManContext);
  const user = item;

  ////// EDIT CONTROL VARIABLES & FUNCTIONS //////

  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setShowEdit(false);
  }, [user]);

  ////// DELETE CONTROL VARIABLES & FUNCTIONS //////

  const [showConfirm, setShowConfirm] = useState(false);

  const handleConfirm = async (choose) => {
    setShowConfirm(false);
    if (choose) {
      const results = await deleteUser(user.user_id);
      if (results.success) {
        popupMsg("UserItem: DeleteUser", `${t("user")} ${user.user_name} ${t("deleted")}`);
      }
    }
  };

  ////// RENDERING //////

  return (
    <>
      <td>{user.user_email}</td>
      <td>{user.user_name}</td>
      <td>{user.user_role}</td>
      <td>
        <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{t("edit")}</Tooltip>}>
          <button
            onClick={() => setShowEdit(true)}
            className="btn text-warning btn-act"
            data-toggle="modal"
          >
            <i className="material-icons">&#xe254;</i>
          </button>
        </OverlayTrigger>

        <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{t("delete")}</Tooltip>}>
          <button
            // onClick={() => handleDelete()}
            onClick={() => setShowConfirm(true)}
            className="btn text-danger btn-act"
            data-toggle="modal"
          >
            <i className="material-icons">&#xe872;</i>
          </button>
        </OverlayTrigger>
      </td>

      {/****** Edit user ******/}

      {showEdit && (
        <EditModal
          title={t("edit")+" "+t("user")}
          doEdit={() => {
            return <EditUserForm userToUpdate={user} />;
          }}
          show={showEdit}
          setShow={setShowEdit}
          close={t('close')}
        />
      )}

      {/****** User delete confirm ******/}

      {showConfirm && (
        <ConfirmModal
          title={t("confirm")}
          message={t("sure_to_delete") + " " + t("user")}
          text={user.user_name}
          show={showConfirm}
          setShow={setShowConfirm}
          confirm={handleConfirm}
          yes={t('yes')}
          no={t('no')}
        />
      )}
    </>
  );
};

export default User;
