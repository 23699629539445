// src/components/Locate.js
// ------------------------

import { useMap } from "react-leaflet";
import { useEffect } from "react";
import Locate from "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.css";

const LocateUser = (props) => {
  const map = useMap();
  const { position } = props;
  /*
	{
			position: "bottomright",
			drawCircle: true,
			follow: true,
			setView: true,
			keepCurrentZoomLevel: true,
			markerStyle: {
				weight: 1,
				opacity: 0.8,
				fillOpacity: 0.8
			},
			circleStyle: {
				weight: 1,
				clickable: false
			},
			icon: {faLocationArrow},
			metric: true,
			strings: {
				title: "Mia posizione",
				popup: "Ti trovi in un raggio di {distance} {unit} da questo punto",
				outsideMapBoundsMsg: "Sembri fuori mappa",
			},
			locateOptions: {
				maxZoom: 18,
				watch: true,
				enableHighAccuracy: true,
				maximumAge: 10000,
				timeout: 10000
			}
		})
	*/

  useEffect(() => {
    var locateControl = new Locate({
      position: position,
      keepCurrentZoomLevel: true,
      initialZoomLevel: 13,
      flyTo: true,
      icon: "fa fa-compass",
      locateOptions: {
        maxZoom: 18,
        watch: true,
        enableHighAccuracy: true,
        maximumAge: 10000,
        timeout: 10000,
      },
    });
    // locateControl.on('click', clickLocate)
    locateControl.addTo(map);
  }, []);

  return null;
};

export default LocateUser;
