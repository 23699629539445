import { createContext, useEffect, useState } from "react";

import UserItem from "../UserItem";
import AddUserForm from "../AddUserForm";

import { useTranslation } from "react-i18next";
import { popupMsg } from "../../../../utils/popupMsg";

import { doGetAllUsers } from "../../../api/doGetAllUsers";
import { doAddUser } from "../../../api/doAddUser";
import { doDeleteUser } from "../../../api/doDeleteUser";
import { doUpdateUser } from "../../../api/doUpdateUser";
import { doGetRoles } from "../../../api/doGetRoles";

export const UserManContext = createContext();

const UserManContextProvider = (props) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const response = await doGetAllUsers();
        if (response.success) {
          setUsers(response.users);
        } else {
          popupMsg(response.function, t(response.stack), t("Error"));
        }
      };
      fetchUsers();
    } catch (err) {
      popupMsg("doGetAllUsers", t(err.stack), t("Error"));
    }

    try {
      const fetchRoles = async () => {
        const response = await doGetRoles();
        if (response.success) {
          const role_names = response.roles.map((role) => role.role_name);
          setRoles(role_names);
        } else {
          popupMsg(response.function, t(response.stack), t("Error"));
        }
      };
      fetchRoles();
    } catch (err) {
      popupMsg("doGetAllUsers", t(err.stack), t("Error"));
    }
  }, []);

  const headers = [t("email"), t("name"), t("role")];
  const addItemForm = AddUserForm;
  const items = users; //.sort((a, b) => (a.name < b.name ? -1 : 1));

  const itemComponent = ({ item }) => {
    return <UserItem item={item} />;
  };

  const addUser = async (user_email, user_password, user_name, user_role) => {
    const response = await doAddUser(user_email, user_password, user_name, user_role);

    if (response.success) {
      setUsers([
        ...users,
        { user_id: response.user.user_id, user_email, user_name, user_role },
      ]);
    } else {
      userManErrorMsg(response, "addUser");
    }
    return response;
  };

  const deleteUser = async (user_id) => {
    const response = await doDeleteUser(user_id);
    if (response.success) {
      setUsers(users.filter((user) => user.user_id !== user_id));
    } else {
      userManErrorMsg(response, "deleteUser");
    }
    return response;
  };

  const updateUser = async (user_id, user_email, user_password, user_name, user_role) => {
    const response = await doUpdateUser(user_id, user_email, user_name, user_role, user_password );
    if (response.success) {
      setUsers(
        users.map((user) =>
          user.user_id === user_id
            ? { user_id, user_email, user_name, user_role, }
            : user
        )
      );
    } else {
      userManErrorMsg(response, "updateUser");
    }
    return response;
  };

  const userManErrorMsg = (response, func) => {
    if (response.type === "client-api") {
      popupMsg(response.function, t(response.stack), t("Error"));
    } else {
      popupMsg(func, t(response.error), t("Error")
      );
    }
  }

  return (
    <UserManContext.Provider
      value={{
        items,
        roles,
        headers,
        itemComponent,
        addItemForm,
        addUser,
        deleteUser,
        updateUser,
        userManErrorMsg,
      }}
    >
      {props.children}
    </UserManContext.Provider>
  );
};

export default UserManContextProvider;
