import { apiError, READ_USER_FROM_TOKEN } from ".";

export async function doReadUserFromToken() {
  try {
    const response = await fetch(READ_USER_FROM_TOKEN, {
      method: "GET",
      headers: { token: localStorage.token },
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doReadUserFromToken");
  }
}
