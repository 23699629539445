import { LayersControl, GeoJSON } from "react-leaflet";
import L from "leaflet";

import { jsonFilter } from "./jsonfilter";
import { jsonStyle } from "./jsonstyle";
import { jsonXInfo } from "./jsonxinfo";
import { onEachFeature } from "./oneachfeature";
import { pointToLayer } from "./pointtolayer";
import { getKeyIdx } from "../helpers";

const addJsonLayer = (
  map,
  initFile,
  jsonLayer,
  layerIdx,
  datasets,
  layerMarkerField,
  filterValues,
  searchFeatures,
  useChecked,
  layerRef
) => {
  console.log("--------------------------------------addJsonLayer");
  const layerInfo = initFile.jsonLayers[layerIdx];
  
  //get extra information from json data
    const { _datasets, _layerMarkerField, _searchFeatures, _filterValues } = jsonXInfo(jsonLayer, layerInfo, layerIdx);
  // console.log("addJsonLayer -> layer _datasets:", layerInfo.name, _datasets)

  datasets[layerIdx] = _datasets;
  layerMarkerField[layerIdx] = _layerMarkerField;
  searchFeatures[layerIdx] = _searchFeatures;
  filterValues[layerIdx] = _filterValues;

  // if (initFile.jsonLayers[layerIdx].keyFields) {
  //   const keyFields = initFile.jsonLayers[layerIdx].keyFields;

  //   filterValues[layerIdx] = Array.apply(null, { length: keyFields.length }).fill([""]);

  //   //console.log("Map -> layerIdx keyfields:", layerIdx, keyFields);
  //   //console.log("Map -> layerIdx filterValues[layerIdx] (1):", layerIdx, filterValues[layerIdx]);
  // } else {
  //   filterValues[layerIdx] = Array.apply(null, { length: 0 });
  // }

  //filterValues[layerIdx] = []
  //console.log("Map -> layerIdx filterValues[layerIdx] (2):", layerIdx, filterValues[layerIdx]);

  const markerFieldIdx = getKeyIdx(layerInfo, layerInfo.markerField)
  // console.log("addJsonLayer -> layer datasets[layerIdx][markerFieldIdx]:", layerInfo.name, datasets[layerIdx][markerFieldIdx]);
  // console.log("addJsonLayer -> layerMarkerField:", layerMarkerField);
  // console.log("addJsonLayer -> filterValues", filterValues);
  // console.log("addJsonLayer -> searchFeatures:", searchFeatures);

  //> console.log(layerInfo.name, layerInfo.geometryType)

  // const layer = L.geoJSON(jsonLayer.data, {
    
  //   style: (feature) => jsonStyle(feature, layerInfo),
  //   onEachFeature: (feature, layer) => onEachFeature(feature, layer, layerInfo, map),
  //   pointToLayer: (feature) => pointToLayer(feature, layerInfo, map),
  //   filter:
  //     layerInfo.geometryType === "Point" ||
  //     layerInfo.geometryType === "LineString" ||
  //     layerInfo.geometryType === "Polygons"
  //       ? (feature) => jsonFilter(feature, layerInfo.keyFields, filterValues[layerIdx])
  //       : () => true
  //   }
  // )
  // console.log('LAYER:', layer)
  // layer.addTo(map);
  // layerRef = layer;

  // return null;


  console.log(`addJsonLayer -> layer added: ${layerIdx} -> ${layerInfo.name}`);
  console.log("addJsonLayer -> filterValues:", filterValues);


  return (
    <LayersControl.Overlay
      key={layerInfo.name}
      name={layerInfo.name}
      checked={useChecked ? layerInfo.checked : false}
    >
      <GeoJSON
        ref={layerRef}
        data={jsonLayer.data}
        style={(feature) => jsonStyle(feature, layerInfo)}
        onEachFeature={(feature, layer) => onEachFeature(feature, layer, layerInfo, map)}
        pointToLayer={(feature) => pointToLayer(feature, layerInfo, map)}
        filter={
          layerInfo.geometryType === "Point" ||
          layerInfo.geometryType === "LineString" ||
          layerInfo.geometryType === "Polygons"
            ? (feature) => jsonFilter(feature, layerInfo.keyFields, filterValues[layerIdx])
            : () => true
        }
      />
    </LayersControl.Overlay>
  );
};

export default addJsonLayer;
