import { apiError } from "../../utils/apiError";
import { GET_JSON } from ".";

export async function doGetJSON(fname) {
  try {
    const response = await fetch(GET_JSON + "/" + fname, {
      method: "GET",
    });
    return await response.json();
    // if (response.ok) {
    //   return await response.json();
    // } else {
    //   return apiError("API Error", "", "doGetJSON");
    // }
  } catch (err) {
    return apiError(err.message, err.stack, "doGetJSON");
  }
}
