import { Modal } from "react-bootstrap";

function EditModal({ title, doEdit, show, setShow, close }) {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {doEdit()}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-warning" onClick={() => setShow(false)}>
          {close}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
