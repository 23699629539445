export function doLogout(history, setUser) {
  localStorage.removeItem("token");

  setUser({
    isLoggedIn: false,
    name: "",
    role: "",
  });

  history.push("/");
}
