import { useContext, useEffect, useState } from "react";
import { Modal, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./style.css";

import Pagination from "../Pagination";

const DataTable = ({ context, title, itemName, pageSize, alertDuration }) => {
  const { t } = useTranslation();
  const { items, headers, itemComponent, addItemForm } = useContext(context);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const handleShowAlert = () => {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, alertDuration);
    };
    handleHideModal();
    return () => {
      handleShowAlert();
    };
  }, [items, alertDuration]);

  const [currPage, setCurrPage] = useState(1);
  const [itemsPerPage] = useState(pageSize);

  const lastItem = currPage * itemsPerPage;
  const firstItem = lastItem - itemsPerPage;
  const currItems = items.slice(firstItem, lastItem);
  const totPages = Math.ceil(items.length / itemsPerPage);

  return (
    <>
      <div className="table-title">
        <h2>
          {title}
          <OverlayTrigger
            overlay={<Tooltip id={`tooltip-top`}>{`${t("add")} ${itemName}`}</Tooltip>}
          >
            <button
              className="btn btn-title"
              onClick={handleShowModal}
            >
              <i className="material-icons icon-title">&#xE147;</i>
            </button>
          </OverlayTrigger>
        </h2>
      </div>

      <table className="table table-hover table-dark">
        <thead>
          <tr className="bg-primary">
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {currItems &&
            currItems.map((item, index) => (
              <tr key={index}>{itemComponent({ item })}</tr>
            ))}
        </tbody>
      </table>

      <Pagination totPages={totPages} setCurrPage={setCurrPage} />

      <Alert show={showAlert} variant="success">
        {`${t("table_updated")}`}
      </Alert>

      <Modal show={showModal} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{`${t("add")} ${itemName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{addItemForm()}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-warning" onClick={handleHideModal}>
            {t("close")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DataTable;
