import Swal from "sweetalert2";
import "./style.css";

export const popupMsg = (caller, msg, title) => {
  const swal = Swal.mixin({
    //showCloseButton: false,
  });

  swal.fire({
    title: title,
    html: msg,
    footer: caller,
  });
};
