import { useEffect, useState } from "react";

const Pagination = ({ totPages, setCurrPage }) => {
  const pageNums = [];
  for (let i = 1; i <= totPages; i++) {
    pageNums.push(i);
  }

  const [currentButton, setCurrentButton] = useState(1);

  useEffect(() => {
    setCurrPage(currentButton);
  }, [currentButton]);

  return (
    <div>
      <nav className="d-flex justify-content-center">
        <ul className="pagination">
          <li className={currentButton === 1 ? "page-item disabled" : "page-item"}>
            <button className={"page-link"} onClick={() => setCurrentButton((prev) => (prev === 1 ? prev : prev - 1))}>
              <i style={{ fontSize: "16px", paddingTop: "1px" }} className="material-icons">&#xe5e0;</i>
            </button>
          </li>
          {pageNums.map((page, index) => (
            <li key={index} className={currentButton === page ? "page-item active" : "page-item"}>
              <button className="page-link" onClick={() => setCurrentButton(page)}>
                {page}
              </button>
            </li>
          ))}
          <li className={currentButton === pageNums.length ? "page-item disabled" : "page-item"}>
            <button className={"page-link"} onClick={() => setCurrentButton((next) => next === pageNums.length ? next : next + 1)}>
              <i style={{ fontSize: "16px", paddingTop: "1px" }} className="material-icons">&#xe5e1;</i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
