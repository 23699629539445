// MappletSidebar.js
// --------------------------------
// 2020.05.28 - modified 2022.03.14

import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { DomEvent } from "leaflet";
import { useMap } from "react-leaflet";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faHome,
  faSearch,
  faCog,
  faLocationArrow,
  faFilter,
  // faRectangleAd,
  // faRectangleList,
  faMap
} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";

import { Sidebar, Tab } from "../../utils/Sidetabs";
import { MapContext } from "../MapContext";
import AutoCompleteText from "../AutocompleteText";
import MapFilter from "../MapFilter";
import MapLayers from "../MapLayers";

// // import { Form } from 'react-bootstrap'

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

const refContainer = (element) => {
  //console.log('element:', element)
  if (element) {
    DomEvent.disableClickPropagation(element).disableScrollPropagation(element);
  }
};

const MapSidebar = forwardRef(({ updateFeatureEvents, setJsonFilter, initSidebar, handleLayers }, ref) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    collapsed: true,
    selected: "home",
  });

  const [filterValuesChanged, setFilterValuesChanged] = useState(false);

  const map = useMap();

  const {
    //non-state info: extra geojson info
    //----------------------------------
    searchFeatures,
    initFile,
    mapFilterRef,
  } = useContext(MapContext);

  // useEffect(() => {
  //   console.log(
  //     "--------------------------------------MapSidebar (first time)"
  //   );
  // }, []);

  // useEffect(() => {
  //   console.log("--------------------------------------MapSidebar (again)");
  // });

  useEffect(() => {
    if (state.selected === "layers") {
      //console.log("MapSidebar -> filterValues (selected=layers):", filterValues)
      setFilterValuesChanged(true);
    }
  }, [state.selected])

  const [POI, setPOI] = useState("<span>Places</span>");

  var firstTime = true;

  useImperativeHandle(ref, (places) => ({
    updatePlaces(places) {
      // used by syncSidebar (in Mapplet component)
      setPOI(places);
    },
  }));

  useEffect(() => {
    updateFeatureEvents();
  }, [POI]);

  var firstTime = true;

  function onSidebarClose() {
    setState({ collapsed: true });
  }

  function onSidebarOpen(id) {
    setState({
      collapsed: false,
      selected: id,
    });
    if (firstTime && id === "places") {
      initSidebar();
      firstTime = false;
    }
  }

  function setContent() {
    //console.log("setContent -> POI:", POI);
    return <span>{parse(POI)}</span>;
  }

  // console.log('MappletSidebar-searchFeatures:', props.searchFeatures)

  // // const dir = "./data/"
  // // const sel = "init*.*"

  // // const fdata = loadJSON("http://127.0.0.1/dirGlobe.php?dir=" + dir + "&sel=" + sel)

  /*
  function dashboardContent () {
    // const fdata = loadJSON("http://127.0.0.1/dirGlobe.php?dir=" + dir + "&sel=" + sel)
    let content = ''
    fdata.forEach((fname) => {
      content += fname + '<br />'
    })
    return content
  }
  */

  const lorem = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ornare eu dolor nec ultrices. Nunc faucibus lacus vel tortor sagittis, vitae facilisis orci tristique. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse potenti. Curabitur in justo non tortor ultricies elementum. Morbi condimentum, nisi vel rhoncus euismod, tellus metus pretium odio, quis gravida est felis eget augue. Nulla gravida eu sem et commodo. Nam scelerisque elit vel tempus fermentum. Donec iaculis erat a pretium congue. Ut fringilla, lectus in gravida varius, ante orci porttitor nibh, consequat maximus lectus massa sed nulla. Cras vehicula vulputate urna, ultrices rutrum nisi rhoncus quis.
  Donec sed magna in odio lobortis posuere. Pellentesque mi nibh, vulputate vel magna eu, suscipit placerat nulla. Sed sit amet sagittis orci. In hac habitasse platea dictumst. Morbi consectetur sed augue non mattis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam at efficitur diam.
  Quisque pretium ligula at dolor rutrum, sit amet euismod enim faucibus. Sed posuere neque ac lectus dictum dignissim. Sed erat nibh, convallis a dolor at, porttitor vestibulum leo. Duis maximus neque turpis, quis porta justo tempor elementum. Vestibulum lacinia, sapien vitae rutrum sodales, ligula arcu accumsan mi, vitae pulvinar justo magna eu diam. Sed a ex tincidunt, blandit urna sed, tempor ligula. Nullam sollicitudin sem at odio porta, eget volutpat quam dictum.
  Cras rhoncus accumsan ligula, id mattis felis rutrum non. Mauris eget nunc nibh. Donec vehicula nibh porttitor arcu feugiat, id tempus tortor euismod. Duis quis fringilla enim. Mauris ultrices tortor sed convallis mollis. Ut mattis ultricies est vitae tincidunt. Phasellus congue nibh et purus semper semper. Mauris sed sagittis velit. Etiam sodales ante quis nisl congue, a ullamcorper lacus lacinia. Mauris sit amet lectus libero. Vestibulum vitae tincidunt dolor.
  Sed vel blandit magna, vitae efficitur turpis. Phasellus a tellus a nibh tempus blandit. Vivamus ornare iaculis magna a dapibus. Vivamus est nunc, pellentesque pellentesque urna eu, suscipit pulvinar lectus. Vivamus non aliquet odio, nec suscipit metus. Sed cursus hendrerit scelerisque. Praesent tempus, sem at ultrices rutrum, velit lectus gravida metus, id fermentum ligula ipsum vel ligula. In ut diam feugiat, mattis ligula vel, feugiat libero. Pellentesque vitae massa eu nulla aliquet aliquet commodo vitae lorem. Mauris in tempus arcu. Donec ornare tempor dui in eleifend. Etiam purus mauris, finibus nec nunc id, facilisis dignissim felis. `;

  return (
    <div ref={refContainer}>
      <Sidebar
        id="sidebar"
        position="left"
        collapsed={state.collapsed}
        closeIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        selected={state.selected}
        onOpen={onSidebarOpen}
        onClose={onSidebarClose}
      >
        {/* <Tab id="logo" header="Logo" icon={<Watermark />}></Tab> */}
        <Tab
          id="home"
          key="home"
          header="Home"
          icon={<FontAwesomeIcon icon={faHome} title={t("Home")} />}
        >
          <p>mappletFilename</p>

          {/* <div className="app-component">

        <Form>
          <Form.Group controlId="mappletFilename">
            <Form.Label><b>Mapplet file:</b></Form.Label>
            <Form.Control as="select" size="sm" custom 
              onChange={e => {
                console.log('    (MappletSidebar) - new selected filename:', e.target.value)
                updateMappletFile('/data/' + e.target.value)
              }}
            >
              {fdata.map((filename, i) => {
                const file = filename.split("/").pop()
                if (file === mappletFilename.split("/").pop())
                  return (<option key={i} selected>{file}</option>) 
                else
                  return (<option key={i}>{file}</option>) 
              })}
            </Form.Control>
          </Form.Group>
        </Form>

      </div> */}
        </Tab>

        <Tab
        id="layers"
        key="layers"
        header={t("Layers")}
        icon={<FontAwesomeIcon icon={faMap} title={t("Layers")} />}
        >
        <MapLayers
          setJsonFilter={setJsonFilter}
          handleLayers={handleLayers}
          filterValuesChanged={filterValuesChanged}
          setFilterValuesChanged={setFilterValuesChanged}
        />
        </Tab>

        <Tab
          id="search"
          key="search"
          header={t("Search")}
          icon={<FontAwesomeIcon icon={faSearch} title={t("Search")} />}
        >
          <div className="app-component">
            <AutoCompleteText
              initFile={initFile}
              searchFeatures={searchFeatures}
              layers={initFile.jsonLayers}
              // setView={props.setView}
              map={map}
              t={t} // translation function (can't use useTranslation in class comp)
              selected={state.selected}
            />
          </div>
        </Tab>

        <Tab
          id="places"
          key="places"
          header={t("Places")}
          icon={<FontAwesomeIcon icon={faLocationArrow} title={t("Places")} />}
        >
          {setContent()}
        </Tab>

        <Tab
          id="filter"
          key="filter"
          header={t("Filter")}
          icon={<FontAwesomeIcon icon={faFilter} title={t("Filter")} />}
        >
          <div className="app-component">
            <MapFilter ref={mapFilterRef} setJsonFilter={setJsonFilter}/>
          </div>
        </Tab>

        {/* <Tab
          id="test"
          key="test"
          header={t("Test")}
          icon={<FontAwesomeIcon icon={faRectangleList} title={t("Test")} />}
        >
          <MultiSelectTest />
          <MultiSelectTest />
        </Tab> */}

        <Tab
          id="settings"
          key="settings"
          header={t("Settings")}
          anchor="bottom"
          icon={<FontAwesomeIcon icon={faCog} title={t("Settings")} />}
        >
          <p>{lorem}</p>
        </Tab>
      </Sidebar>
    </div>
  );
});

export default MapSidebar;
