import React, { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { doLogout } from "../../../auth/api/doLogout";

import { UserContext } from "../UserContext";

import LoginModal from "../../../utils/LoginModal";
import LoginForm from "../LoginForm";

export const UserInfo = () => {
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    setShowLogin(false);
  }, [user]);

  const loggedIn = () => {
    return (
      <>
        {t("is_logged_in")} | {user.name} | {user.role}
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("logout")}</Tooltip>}>
          <button
            onClick={() => doLogout(history, setUser)}
            className="btn text-success btn-act"
          >
            <i style= {{fontSize: "28px"}} className="material-icons">&#xe9ba;</i>
          </button>
        </OverlayTrigger>
      </>
    );
  };

  const notLoggedIn = () => {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("login")}</Tooltip>}>
        <button
          className="btn text-success btn-act"
          onClick={() => setShowLogin(true)}
        >
          <i style= {{fontSize: "28px"}} className="material-icons">&#xea77;</i>
        </button>
      </OverlayTrigger>
    );
  };

  return (
    <>
      {user.isLoggedIn && loggedIn()}
      {!user.isLoggedIn && notLoggedIn()}
      {showLogin && (
        <LoginModal
          title="Login"
          doLogin={() => {
            return <LoginForm />;
          }}
          show={showLogin}
          setShow={setShowLogin}
        />
      )}
    </>
  );
};
