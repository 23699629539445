import L from "leaflet";
import { formatProps, getLayerIdx } from "../../utils/helpers";
import { jsonFilter } from "../../utils/json/jsonfilter";
import './style.css'

function syncSidebar(map, initFile, layersControlRef, jsonLayersData, filterValues) {
  // This function is called to update the full content of the place-of-interest sidebar
  // with all the places ('Point' type layers) currently visible on the map.

  const lcontrol = layersControlRef.current; // The Leaflet layerControl instance.
  const layers = lcontrol._layers; // The list of current layers.

	var placesContent = 
    '<div id="sidebar">'+
      '<div class="sidebar-wrapper">'+
        '<div class="panel panel-default" id="features">'+
          '<div class="sidebar-table">'+
            '<table class="table table-hover" id="feature-list">'+
            //   '<thead class="hidden">'+
            //     '<th>Icon</th>'+
            //     '<th>Name</th>'+
            //     '<th>Chevron</th>'+
            //   '</thead>'+
              '<tbody class="list">';

  layers.forEach((layer) => {
    if (layer.overlay) {
      if (map.hasLayer(layer.layer)) {
				const layerIdx = getLayerIdx(initFile.jsonLayers, layer.name);
				//TODO: const layerInfo = initFile.jsonLayers[layerIdx]
				if (layerIdx >= 0 && initFile.jsonLayers[layerIdx].geometryType === "Point") {
					jsonLayersData[layerIdx].data.features.forEach((feature) => {
						if (map.getBounds().contains([feature.geometry.coordinates[1], feature.geometry.coordinates[0]])) {
							const keyFields = initFile.jsonLayers[layerIdx].keyFields;
							const layerFilterValues = filterValues[layerIdx];

							if(jsonFilter(feature, keyFields, layerFilterValues)) {
								placesContent += 
									'<tr class="feature-row"'+
										'id="'+L.stamp(feature)+'"'+ 
										'lat="'+feature.geometry.coordinates[1]+'"'+ 
										'lng="'+feature.geometry.coordinates[0]+'">'+
											'<td>'+ 
												formatProps(
													feature.properties,
													initFile.jsonLayers[layerIdx].placeFields,
													initFile.jsonLayers[layerIdx].placeFormat
												)+
												'<br>('+layer.name+')'+
											'</td>'+
									'</tr>'
							}
						}
					});					
				}
      }
    }
  });

  placesContent += '</tbody>'+
            '</table>'+
          '</div>'+
        '</div>'+
      '</div>'+
    '</div>';

  return placesContent;
}

export default syncSidebar;
