import { apiError } from "../../utils/apiError";
import { GET_ALL_USERS } from ".";

export async function doGetAllUsers() {
  try {
    const response = await fetch(GET_ALL_USERS, {
      method: "GET",
      headers: { token: localStorage.token },
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doGetAllUsers");
  }
}
