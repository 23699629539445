import L from "leaflet";

export function jsonXInfo(jsonLayer, overlay, layerIdx) {
  const data = jsonLayer.data;

	var _datasets = [];
  var _layerMarkerField = [];
	var _searchFeatures = [];
  var _filterValues = [];

  //-----------------------------------------------------------------------
  //get unique keyFields values (if keyFields is defined)
  //-----------------------------------------------------------------------
  if (overlay.keyFields) {
    _datasets = overlay.keyFields.map((keyField) => {
      return [...new Set(data.features
        .map((feature) => feature.properties[keyField]))]
        .filter((value) => value !== null).sort();
    });
    _filterValues = Array.apply(null, { length: overlay.keyFields.length }).fill([""]);
  }

  //-----------------------------------------------------------------------
  //create 'filter categories' for Point, LineString and Polygons overlay layers,
  //from the sorted 'categories' found (used in the legend to check/uncheck
  //- show/hide - data associated to the corresponding category)
  //-----------------------------------------------------------------------

  if (
    overlay.geometryType === "Point" ||
    overlay.geometryType === "LineString" ||
    overlay.geometryType === "Polygons"
  ) {
    _layerMarkerField = overlay.markerField;

    //create 'search features' array for the Search command;
    //these data are displayed when a feature is selected from the list returned
    //by the AutoCompleteText component (only for 'Point' layers)
    //-----------------------------------------------------------------------
    if (overlay.geometryType === "Point") {
      // add features to the search array
      data.features.forEach((feature) => {
				_searchFeatures.push([]);
        const featureIdx = _searchFeatures.length - 1;

        if (feature.geometry.coordinates == null) {
          console.log("ERROR: null coordinates found!");
          console.log(
            "idx:",
            featureIdx,
            "- feature.geometry.coordinates:",
            feature.geometry.coordinates
          );
          console.log("feature:", feature);
        }

        // add common fields
        _searchFeatures[featureIdx] = { source: overlay.name };
        _searchFeatures[featureIdx]["id"] = L.stamp(feature);
        _searchFeatures[featureIdx]["lat"] = feature.geometry.coordinates[1];
        _searchFeatures[featureIdx]["lng"] = feature.geometry.coordinates[0];

        // add layer specific information fields
        if (overlay.infoFields)
          overlay.infoFields.forEach((searchField) => {
            _searchFeatures[featureIdx][searchField] =
              feature.properties[searchField];
          });
      });
    }
  }

  return {
    _datasets,
		_layerMarkerField,
    _searchFeatures,
    _filterValues
  };
}
