import { useContext, useState } from "react";
import { Form, Button, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { popupMsg } from "../../../../utils/popupMsg";
import { UserManContext } from "../UserManContext";

const EditUserForm = ({ userToUpdate }) => {
  const { t } = useTranslation();

  const { roles, updateUser } = useContext(UserManContext);

  const [updatedUser, setUpdatedUser] = useState({
    email: userToUpdate.user_email,
    name: userToUpdate.user_name,
    role: userToUpdate.user_role,
    password: ""
  })

  const {email, password, name, role} = updatedUser;

  const onInputChange = (e) => {
    setUpdatedUser({ ...updatedUser, [e.target.name]: e.target.value });
  };

  const edit_user = t("edit") + " " + t("user");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(roles.includes(role))) {
      popupMsg("EditUserForm", t("role") + ": " + t("required_field"), t("Error"));
    } else {
      const results = await updateUser(userToUpdate.user_id, email, password, name, role);
      if (results.success) {
        setUpdatedUser({ email: "", password: "", name: "", role: t("role") + " *" })
        popupMsg("", `${t("User")} '${updatedUser.name}' ${t("updated")}`, edit_user);
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder={t("email") + " *"}
            name="email"
            value={email}
            onChange={(e) => onInputChange(e)}
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            name="password"
            placeholder={t("password")}
            value={password}
            onChange={(e) => onInputChange(e)}
            autoComplete="on"
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={t("name") + " *"}
            name="name"
            value={name}
            onChange={(e) => onInputChange(e)}
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            as="select"
            name="role"
            value={role}
            onChange={(e) => onInputChange(e)}
          >
            <option>{t("role") + " *"}</option>
            {roles.map((role) => (
              <option key={role}>{role}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button variant="success" type="submit" block>
          {t("update")} {t("user")}
        </Button>
      </Form>
    </div>
  );
};

export default EditUserForm;
