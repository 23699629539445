import { apiError, GET_ROLES } from ".";

export async function doGetRoles() {
  try {
    const response = await fetch(GET_ROLES, {
      method: "GET",
      headers: { token: localStorage.token },
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doGetRoles");
  }
}