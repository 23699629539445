import { useState, useContext, useLayoutEffect, useEffect } from "react";
import L from "leaflet";
import { LayersControl, MapContainer, ScaleControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import Loading from "../../../utils/Loading";

import { MapContext } from "../MapContext";

import "./style.css";
import MapInfo from "../MapInfo";
import MapSidebar from "../MapSidebar";
import LocateUser from "../LocateUser";
//import MapLegend from "../MapLegend";
import { Watermark } from "../Watermark";

import { getLayerIdx, getOverlays } from "../../utils/helpers";

import sortJsonLayers from "../../utils/json/sortjsonlayers";
import addBaseLayer from "../../utils/addbaselayer";
import addWmsLayer from "../../utils/addwmslayer";
import addJsonLayer from "../../utils/json/addjsonlayer";
import syncSidebar from "../POI";
import setLocation from "../../utils/setlocation";

const Map = () => {
  const {
    //state variables: setMappletInit
    //-------------------------------
    mappletInit,
    // setMappletInit,

    //state variables (children can't change them)
    //--------------------------------------------
    initFile,
    urlMapplet,
    jsonLayersData,

    //non-state info: extra geojson info
    //----------------------------------
    datasets,
    layerMarkerField,
    searchFeatures,
    filterValues,

    //non-state variables: refs
    //-------------------------
    layersControlRef,
    scaleControlRef,
    sidebarRef,
    mapFilterRef,
  } = useContext(MapContext);

  // var { filterValues } = useContext(MapContext)

  const [map, setMap] = useState();

  var zoom = null;
  var center = null;
  var useChecked = true;

  var places = null;

  useEffect(() => {
    console.log("Map -> filterValues:", filterValues)
  }, [filterValues])

  useEffect(() => {
    console.log("Map -> datasets:", datasets)
  }, [datasets])
 
  useLayoutEffect(() => {
    localStorage.trace = "M";
  }, []);

  // useEffect(() => {
  //   localStorage.trace = "M";
  //   console.log("--------------------------------------Map []                   ->", localStorage.trace);
  //   console.log("Map -> map:", map)
  //   console.log("Map -> mappletInit:", mappletInit)
  //   console.log("Map -> urlMapplet:", urlMapplet)
  //   console.log("Map -> localStorage.mapplet_init:", localStorage.mapplet_init)

  //   // if (!urlMapplet && localStorage.mapplet_init) {
  //   //   setMappletInit(localStorage.mapplet_init);
  //   // }
  // }, []);

  // useEffect(() => {
  //   localStorage.trace = localStorage.trace + "a";
  //   console.log("--------------------------------------Map ()                   ->", localStorage.trace);
  //   console.log("Map -> map:", map)
  //   console.log("Map -> mappletInit:", mappletInit)
  // });

  useEffect(() => {
    localStorage.trace = localStorage.trace + "i";
    console.log("--------------------------------------Map [mappletInit]        ->", localStorage.trace);
    //console.log("Map -> map:", map)
    //console.log("Map -> mappletInit:", mappletInit)

    //store mapplet init file name
    if (mappletInit) {
      localStorage.mapplet_init = mappletInit;
    }
  }, [mappletInit]);

  useEffect(() => {
    localStorage.trace = localStorage.trace + "P";
    console.log(
      "--------------------------------------Map [map]                ->", localStorage.trace
    );
    // console.log("Map -> map:", map);
    // console.log("Map -> mappletInit:", mappletInit);

    if (map) {
      //console.log("Map [map] -> map:", map);
      map.on("overlayadd", (e) => onOverlayAdd(e));
      map.on("overlayremove", (e) => onOverlayRemove(e));
      map.on("moveend", (e) => onMoveEnd(e));

      setInitialView();

      // map.addLayer(highlight);
      // highlight.clearLayers().addLayer(notifyMarker(center[0], center[1]));
      // console.log("Map -> set initial view and event handlers");
      // if (map.hasLayer(highlight))
      //   console.log("Map -> map.hasLayer ", highlight);
    }

    if (layersControlRef.current) {
      if (!localStorage.mapplet_overlays) {
        const overlays = getOverlays(layersControlRef.current);
        //store overlays layers status
        localStorage.mapplet_overlays = JSON.stringify(overlays);
        //console.log("Map -> store overlays:", overlays);
      } else {
        setActiveLayers();
      }

      // const sidebarContent = syncSidebar(map, initFile, layersControlRef, jsonLayersData, filterValues);
      // console.log(sidebarContent);
      hideLayersControl();
    }
    if (localStorage.mapplet_overlays) useChecked = false;
  }, [map]);

  const setInitialView = () => {
    zoom = localStorage.mapplet_zoom || initFile.zoom;
    center = localStorage.mapplet_center || initFile.center;

    // retrieve & set zoom and map center
    if (isNaN(zoom)) {
      zoom = initFile.zoom; //default
    }
    if (center === localStorage.mapplet_center) {
      var regex = /[+-]?\d+(\.\d+)?/g;
      center = center.match(regex).map(function (v) {
        return parseFloat(v);
      });
    }
    map.setView({ lat: center[0], lng: center[1] }, zoom);

    //store current base layer
    map.on("baselayerchange", (e) => {
      localStorage.mapplet_baseLayer = e.name;
    });

    //store zoom & center level
    map.on("moveend", (e) => {
      localStorage.mapplet_center = map.getCenter();
    });
    map.on("zoomend", (e) => {
      localStorage.mapplet_zoom = map.getZoom();
    });

    //store mapplet init file name
    if (mappletInit) localStorage.mapplet_init = mappletInit;
  };

  const setActiveLayers = () => {
    if (localStorage.mapplet_baseLayer) {
      var idx = null;
      if ((idx = getLayerIdx(layersControlRef.current._layers, localStorage.mapplet_baseLayer)) !== -1) {
        map.removeLayer(layersControlRef.current._layers[initFile.currentBaseMap].layer);
        
      }
      map.addLayer(layersControlRef.current._layers[idx].layer);
    }
    if (localStorage.mapplet_overlays) {
      const overlays = JSON.parse(localStorage.mapplet_overlays);
      //console.log("Map-setActiveLayers -> overlays:", overlays);

      for (const [layerName, added] of Object.entries(overlays)) {
        //console.log(`${layerName}: ${added}`);
        idx = getLayerIdx(layersControlRef.current._layers, layerName);

        if (idx >= 0) {
          if (added) map.addLayer(layersControlRef.current._layers[idx].layer);
          else map.removeLayer(layersControlRef.current._layers[idx].layer);
        }
      }
    }
  };

  function onOverlayAdd(eventLayer) {
    // This function is used to display the corresponding legend, when a layer
    // is checked in the layer control.
    // The DOM element is identified through the className, which contains
    // the layer name.

    //> console.log("Map: onOverlayAdd - eventLayer.name =", eventLayer.name);

    const className = "Legend " + eventLayer.name + " leaflet-control";
    const element = document.getElementsByClassName(className);

    if (element[0]) element[0].style.display = "block";

    //update/create localStorage.mapplet_overlays
    if (localStorage.mapplet_overlays) {
      const overlays = JSON.parse(localStorage.mapplet_overlays);
      overlays[eventLayer.name] = true;
      localStorage.mapplet_overlays = JSON.stringify(overlays);
    } else {
      const overlays = getOverlays(layersControlRef.current);
      localStorage.mapplet_overlays = JSON.stringify(overlays);
    }

    updateSidebar();
    layersHaveChanged();
  }

  function onOverlayRemove(eventLayer) {
    // When a layer is unchecked in the layer control, this function is used
    // to hide the corresponding legend.
    // The DOM element is identified through the className, which contains
    // the layer name.

    //console.log("Map: onOverRemove - eventLayer.name =", eventLayer.name);

    const className = "Legend " + eventLayer.name + " leaflet-control";
    const element = document.getElementsByClassName(className);
    if (element[0]) element[0].style.display = "none";

    //update/create localStorage.mapplet_overlays
    if (localStorage.mapplet_overlays) {
      const overlays = JSON.parse(localStorage.mapplet_overlays);
      overlays[eventLayer.name] = false;
      localStorage.mapplet_overlays = JSON.stringify(overlays);
    } else {
      const overlays = getOverlays(layersControlRef.current);
      localStorage.mapplet_overlays = JSON.stringify(overlays);
    }

    updateSidebar();
    layersHaveChanged();
  }

  function layersHaveChanged() {
    //console.log(mapFilterRef)
    if (mapFilterRef.current !== null) mapFilterRef.current.setRefresh();
  }

  function onMoveEnd() {
    // places = syncSidebar(map, initFile, layersControlRef, jsonLayersData, filterValues);
    // if (sidebarRef.current !== null) sidebarRef.current.updatePlaces(places);
    //console.log(places);
    updateSidebar();
  }

  function updateSidebar() {
    if (map && layersControlRef.current)
      places = syncSidebar(
        map,
        initFile,
        layersControlRef,
        jsonLayersData,
        filterValues
      );
    if (sidebarRef.current !== null) sidebarRef.current.updatePlaces(places);
  }

  function updateFeatureEvents() {
    // This function is called by MappletSidebar component, whenever the places list (POI)
    // changes, to update feature events' handlers associated to 'feature-row'(s).

    var elements = document.getElementsByClassName("feature-row");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", locationView, false);
      elements[i].addEventListener("mouseover", locationOver, false);
      elements[i].addEventListener("mouseout", locationOut, false);
    }
  }

  var marker;

  function locationView() {
    // This function is called when a place is selected from
    // the place-of-interest list in the sidebar.
    if (marker) marker.remove();
    const lat = this.getAttribute("lat");
    const lng = this.getAttribute("lng");
    setLocation(map, lat, lng, 2500);
  }

  function locationOver(event) {
    const lat = this.getAttribute("lat");
    const lng = this.getAttribute("lng");
    marker = L.marker([lat, lng]).addTo(map);
  }

  function locationOut(event) {
    marker.remove();
  }

  function setFilter(layerName, values, keys) {
    // This function is used by MappletFilter component as a callback function,
    // whenever the filter values of the specified layer change.

    updateLayers(map, initFile, layersControlRef, layerName, values, keys);
    updateSidebar();
  }

  function updateLayers(map, initFile, layersControlRef, layerName, values) {
    //, keys) {
    // This function is used to update the filter values, as defined by the MappletFilter
    // component, and display the updated layer accordingly.

    const lcontrol = layersControlRef.current; // The Leaflet layerControl instance.
    const layers = lcontrol._layers; // The list of current layers.
    const layerIdx = getLayerIdx(initFile.jsonLayers, layerName); // Get the index of the layer to be updated.

    ///> console.log(layerName, layerIdx)
    ///> console.log(values)
    ///> console.log(keys)

    layers.forEach((layer) => {
      if (map.hasLayer(layer.layer)) {
        // Check if the layer is displayed
        ///> console.log('Map -> updateLayers-map has layer:', layerName)
        if (layer.name === layerName) {
          // (layer.layer is the Leaflet layer object).
          //console.log('Map -> updateLayers-layer', layerName, 'found')
          //console.log("Map-updateLayers -> filterValues:", filterValues);
          //filterValues[layerIdx] = values                     // Update filter values and keys
          filterValues[layerIdx] = values;
          //filterKeys[layerIdx] = keys                         // for the current layer.

          //console.log('Map -> updateLayers-filterValues:', filterValues[layerIdx])
          ///> console.log('filterKeys:', filterKeys[layerIdx])
          ///> console.log('layer:', layer)
          layer.layer.clearLayers(); // Clear the layer.
          layer.layer.addData(jsonLayersData[layerIdx].data); // Add the layer again, taking into account
          // the filter categories (the filter function
          // is automatically called).
        }
      }
    });
  }

  function updateJsonLayers (map, layerName, values, keyFieldIdx) {
    // This function is used to update the filter values, as modified by the Layers 
    // component, and display the updated layer accordingly

    const lcontrol = layersControlRef.current;
    const layers   = lcontrol._layers;
    const layerIdx = getLayerIdx(initFile.jsonLayers, layerName);
    const layerKeyFields = initFile.jsonLayers[layerIdx].layerKeyFields;

    // //initialize filterValues[] array, if not yet done
    // if (filterValues[layerIdx].length === 0) {
    //   filterValues[layerIdx] = Array(layerKeyFields.length);
    //   for (let i; i < layerKeyFields.length; i++)
    //     filterValues[layerIdx][i] = [];
    // }

    layers.forEach((layer) => {
      //check if the layer has been added to the map
      if (map.hasLayer(layer.layer) && layer.name === initFile.jsonLayers[layerIdx].name) {

        //update filter values for the defined keyField
        filterValues[layerIdx][keyFieldIdx] = values;

        //remove current JSON layer from map
        layer.layer.clearLayers()

        //add the layer to the map again, with new filter values
        //(used by jsonFilterfunction)
        layer.layer.addData(jsonLayersData[layerIdx].data)
        return;
      }
    })
  }

  function setJsonFilter (layerName, values, keyFieldIdx) {
    // This function is passed as a callback function to the 
    // Layers component and it is called whenever a filter category
    // is checked/unchecked

    updateJsonLayers(map, layerName, values, keyFieldIdx);
    updateSidebar();
  }

  function hideLayersControl() {
    var lc = document.getElementsByClassName('leaflet-control-layers');
    lc[0].style.visibility = 'hidden';
  }

  function handleLayers(layer, addFlag) {
    const layerIdx = getLayerIdx(layersControlRef.current._layers, layer.name)

    //console.log(layersControlRef.current._layers[layerIdx])
    const layerInstance = layersControlRef.current._layers[layerIdx];

    if (addFlag) {
      //console.log('add layer', layer.name);
      map.addLayer(layerInstance.layer);
    } else {
      //console.log('remove layer', layer.name)
      map.removeLayer(layerInstance.layer)
    }
  }

  return (
    <div>
      {!initFile ? (
        <Loading />
      ) : (
        <>
          <MapContainer
            zoom={zoom}
            center={center}
            scrollWheelZoom={true}
            whenCreated={setMap}
            zoomControl={false}
          >
            {map && (
              <>
                <Watermark position="topright" />

                <MapSidebar
                  ref={sidebarRef}
                  initSidebar={updateSidebar}
                  updateFeatureEvents={updateFeatureEvents}
                  setFilter={setFilter}
                  setJsonFilter={setJsonFilter}
                  handleLayers={handleLayers}
                />
                <MapInfo position="bottomleft" />
                <ScaleControl
                  position="bottomright"
                  imperial={false}
                  ref={scaleControlRef}
                />
                <LocateUser position="bottomright" />

                <LayersControl position="topright" ref={layersControlRef}>
                  {initFile.baseLayers.map((layer) => addBaseLayer(layer))}
                  {initFile.wmsLayers.map((layer) => addWmsLayer(layer))}
                  {jsonLayersData
                    .sort((a, b) =>
                      sortJsonLayers(a, b, jsonLayersData, initFile)
                    )
                    .map((jsonLayer, index) =>
                      addJsonLayer(
                        map,
                        initFile,
                        jsonLayer,
                        index,
                        datasets,
                        layerMarkerField,
                        filterValues,
                        searchFeatures,
                        useChecked
                      )
                    )}
                </LayersControl>

                {/* <MinimapControl position="topright" /> */}
                {/* <Control position="bottomright">
                  <ActionButton ref={buttonRef} />
                </Control> */}
              </>
            )}
          </MapContainer>
        </>
      )}
    </div>
  );
};

export default Map;
