import { useState } from "react";
import "flag-icon-css/css/flag-icons.min.css";

import "./style.css";

function Dropdown({ selected, setSelected, languages }) {
  const [isActive, setIsActive] = useState(false);

  const flag = (code) => {
    return `flag-icon flag-icon-${code === "en" ? "gb" : code} mx-2`
  }

  return (
    <div className="dropdown">
      <div className="dropdown-btn" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsActive(!isActive)
      }}>
        <span className={flag(selected)}></span>
        <span className="fa fa-caret-down"></span>
      </div>
      {isActive && (
        <div className="dropdown-content">
          {languages.map((language) => (
            <div key={language.country_code}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelected(language.country_code);
								setIsActive(false)
              }}
              className="dropdown-item"
            >
							<span className={flag(language.country_code)}></span>
              {language.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
