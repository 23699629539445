import { Modal } from "react-bootstrap";

function LoginModal({ title, doLogin, show, setShow }) {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {doLogin()}
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
