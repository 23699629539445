import { createContext, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { popupMsg } from "../../../utils/popupMsg";
import { doGetInitFile } from "../../api/doGetInitFile";
import { doGetJSON } from "../../api/doGetJson";
import { doGetDbJSON } from "../../api/doGetDbJson";


// marker display fix /////////////////////////////////////////////

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

///////////////////////////////////////////////////////////////////

export const MapContext = createContext();

const MapContextProvider = (props) => {
  const { t, i18n } = useTranslation();
  const [mappletInit, setMappletInit] = useState(props.mappletInitFile);

  const [{ initFile, jsonLayersData, urlMapplet }, setState] = useState({
    initFile: null,
    urlMapplet: props.urlMapplet,
    jsonLayersData: [],
  });

  const layersControlRef = useRef(null);
  const scaleControlRef = useRef(null);
  const sidebarRef = useRef(null);
  const mapFilterRef = useRef(null);
  const mapLayersRef = useRef(null);

  var datasets = [];
  var layerMarkerField = [];
  var filterValues = [];
  var searchFeatures = [];

  useLayoutEffect(() => {
    localStorage.trace = localStorage.trace + "I";
    console.log(
      "--------------------------------------MapContext [mappletInit] ->",
      localStorage.trace
    );

    async function initState(mappletInit) {
      console.log("MapContext-initState");
      let _initFile = null;
      let _jsonLayersData = [];

      if (mappletInit) {
        try {
          const getInit = await doGetInitFile(mappletInit);
          if (getInit.success) {
            // getInit = {
            //   success,   --> true
            //   initFile,  --> mapplet init file
            // }
            _initFile = getInit.initFile;
            _initFile.jsonLayers.map(async (jsonLayer, index) => {
              try {
                // //--------------------------------
                // //load json language files, if any
                // //--------------------------------

                if (i18n && jsonLayer.languages) {
                  const jsonLangIt = await doGetJSON(jsonLayer.languages["it"]);
                  if (jsonLangIt.success) {
                    for (var key in jsonLangIt.data) {
                      //console.log(key+": "+jsonLangIt.data[key])
                      i18n.addResource(
                        "it",
                        "translation",
                        key,
                        jsonLangIt.data[key]
                      );
                    }
                    //console.log(jsonLangIt.data)
                    const jsonLangEn = await doGetJSON(
                      jsonLayer.languages["en"]
                    );
                    if (jsonLangEn.success) {
                      for (var key in jsonLangEn.data) {
                        //console.log(k+": "+jsonLangEn.data[key])
                        i18n.addResource(
                          "en",
                          "translation",
                          key,
                          jsonLangEn.data[key]
                        );
                      }
                    }
                  }
                }

                //-------------------------
                //load json from file or db
                //-------------------------

                const getJsonFunc = jsonLayer.datatype === "file" ? doGetJSON : doGetDbJSON;
                const minus = (jsonLayer.notLoaded) ? jsonLayer.notLoaded : null;

                const getJson = await getJsonFunc(jsonLayer.fname, minus);
                if (getJson.success) {
                  // getJson = {
                  //   success,   --> true
                  //   fname,     --> geojson file name
                  //   data       --> geojson
                  // }
                  _jsonLayersData.push(getJson);
                  //console.log(getJson.data.features[0])

                  //------------------------------------------------
                  //if all json files loaded, set state (and return)
                  //------------------------------------------------
                  if (_jsonLayersData.length === _initFile.jsonLayers.length) {

                    datasets = Array(_initFile.jsonLayers.length);
                    layerMarkerField = Array(_initFile.jsonLayers.length);
                    searchFeatures = Array(_initFile.jsonLayers.length);  
                    filterValues = Array(_initFile.jsonLayers.length);

                    setState((prevState) => {
                      return {
                        ...prevState,
                        initFile: _initFile,
                        jsonLayersData: _jsonLayersData,
                      };
                    });
                  }
                } else {
                  // getJson.success === false
                  // getJson = {
                  //   success,    --> false
                  //   error_type, --> {<type>|pgerr#<pg_err_code>}
                  //   error,      --> {<err_message>|<pg_err_message>}
                  //   function,   --> <function_name>
                  //   stack,      --> <err_stack>
                  // }
                  const func = getJson.function;
                  const message = getJson.error_type + t(getJson.error);
                  const title = t("Error");
                  popupMsg(func, message, title);
                }
              } catch (err) {
                // any generic error thrown while pre-processing geojson
                const func = "doGetJSON";
                const message = t(err.message);
                const title = t("Error");
                popupMsg(func, message, title);
              }
            });
          } else {
            //error reading mapplet init file
            //-------------------------------
            // getInit = {
            //   success,    --> false
            //   error_type, --> {<type>}
            //   error,      --> {<err_message>}
            //   function,   --> <function_name>
            //   stack,      --> <err_stack>
            // }
            const func = getInit.function;
            const message = `${t("Type")}: '${getInit.error_type}'<br/>
               ${t("Error")}: '${getInit.error}'`;
            const title = t("Error");
            popupMsg(func, message, title);

            if (localStorage.mapplet_init) {
              localStorage.removeItem("mapplet_init");
              setMappletInit("default.json");
            }
          }
        } catch (err) {
          //any not handled generic error thrown in the code above (if any...)
          const func = "doGetInitFile";
          const message = t(err.message);
          const title = t("Error");
          popupMsg(func, message, title);
        }
      }
    }

    initState(mappletInit);
  }, []);

  return (
    <MapContext.Provider
      value={{
        //state variables: setMappletInit
        //-------------------------------

        mappletInit,
        setMappletInit,

        //state variables (children can't change them)
        //--------------------------------------------
        initFile,
        urlMapplet,
        jsonLayersData,

        //non-state info: extra geojson info
        //----------------------------------
        datasets,
        layerMarkerField,
        filterValues,
        searchFeatures,

        //non-state variables: refs
        //-------------------------
        layersControlRef,
        scaleControlRef,
        sidebarRef,
        mapFilterRef,
        mapLayersRef,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};

export default MapContextProvider;
