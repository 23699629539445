import { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { popupMsg } from "../../../../utils/popupMsg";
import { UserManContext } from "../UserManContext";

const AddUserForm = () => {
  const { t } = useTranslation();
  const { roles, addUser } = useContext(UserManContext);

  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    name: "",
    role: t("role") + " *",
  });

  const { email, password, name, role } = newUser;

  const onInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const add_user = t("add") + " " + t("user");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!(roles.includes(role))) {
      popupMsg("AddUserForm", t("role") + ": " + t("required_field"), t('Error'));
      setNewUser({ email: "", password: "", name: "", role: t("role") + " *" });
    } else {
      const response = await addUser(email, password, name, role);
      if (response.success) {
        setNewUser({ email: "", password: "", name: "", role: t("role") + " *" });
        popupMsg("", `${t("user")} ${newUser.name} ${t("added")}`, add_user);
      }
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder={t("email") + " *"}
            name="email"
            value={email}
            onChange={(e) => onInputChange(e)}
            autoComplete="on"
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            name="password"
            placeholder={t("password") + " *"}
            value={password}
            onChange={(e) => onInputChange(e)}
            autoComplete="on"
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={t("name") + " *"}
            name="name"
            value={name}
            onChange={(e) => onInputChange(e)}
            autoComplete="on"
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            as="select"
            name="role"
            value={role}
            onChange={(e) => onInputChange(e)}
          >
            <option>{t("role") + " *"}</option>
            {roles.map((role) => (
              <option key={role}>{role}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button variant="success" type="submit" block>
          {add_user}
        </Button>
      </Form>
    </div>
  );
};

export default AddUserForm;
