import L from "leaflet";

export const defaultIcon = L.icon({
  iconUrl: "leaflet/dist/images/marker-icon.png",
  iconSize: [22, 34],
  iconAnchor: [11, 34],
  popupAnchor: [0, -34],
  shadowUrl: "leaflet/dist/images/marker-shadow.png",
  shadowSize: [40, 40],
  shadowAnchor: [12, 40],
});

export function setIconProps(icon, layer) {
  return {
    iconUrl: icon.url,
    iconRetinaUrl: icon.retinaUrl,
    iconSize: layer.markerCommonProps.markerSize,
    iconAnchor: layer.markerCommonProps.markerAnchor,
    popupAnchor: layer.markerCommonProps.popupAnchor,
    shadowUrl: layer.markerCommonProps.shadowUrl,
    shadowSize: layer.markerCommonProps.shadowSize,
    shadowAnchor: layer.markerCommonProps.shadowAnchor,
  };
}

export function setIcon(layer, category) {
  for (var i = 0, len = layer.icons.length; i < len; i++) {
    if (layer.icons[i].category === category) {
      return L.icon(setIconProps(layer.icons[i], layer));
    }
  }
  return defaultIcon;
}

export function setIconImg(layer, category) {
  for (var i = 0, len = layer.icons.length; i < len; i++) {
    if (layer.icons[i].category === category) {
      return (
        <img
          src={layer.icons[i].url}
          alt=""
          width="16"
          height="20"
          style={{ verticalAlign: "middle" }}
        />
      );
    }
  }
  return "";
}

export function formatProps(props, fields, textFormat) {
  var formatted = textFormat;
  for (var i = 0, len = fields.length; i < len; i++) {
    var regexp = new RegExp("\\{" + i + "\\}", "gi");
    formatted = formatted.replace(regexp, props[fields[i]]);
  }
  return formatted;
}

export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export function getLayerIdx(layers, layerName, mode = 0) {
  for (var i = 0, len = layers.length; i < len; i++) {
    if (mode === 0) {
      if (layerName === layers[i].name) return i;
    } else {
      if (layerName === layers[i]) return i;
    }
  }
  return -1;
}

export function getKeyIdx(layer, keyValue) {
  const keyFields = layer.keyFields
  if (keyFields)
    for (var i = 0, len = keyFields.length; i < len; i++)
        if (keyValue === keyFields[i]) return i;
  return -1;
}

export function getOverlays(control) {
  // create hash to hold all layers
  var control, layers;
  layers = {};

  // loop thru all layers in control
  control._layers.forEach(function (obj) {
    var layerName;

    // check if layer is an overlay
    if (obj.overlay) {
      // get name of overlay
      layerName = obj.name;
      // store whether it's present on the map or not
      return (layers[layerName] = control._map.hasLayer(obj.layer));
    }
  });

  return layers;
}

export function getBaseLayers(control) {
  // create hash to hold all layers
  var control, layers;
  layers = {};

  // loop thru all layers in control
  control._layers.forEach(function (obj) {
    var layerName;

    // check if layer is not an overlay => it's a base layer
    if (!obj.overlay) {
      // get name of overlay
      layerName = obj.name;
      // store whether it's present on the map or not
      return (layers[layerName] = control._map.hasLayer(obj.layer));
    }
  });

  return layers;
}

export function getRandomId(length) {
  for (
    var s = "";
    s.length < length;
    s +=
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".charAt(
        (Math.random() * 62) | 0
      )
  );
  return s;
}

export function isVisibleLayer(map, layersControlRef, layerName) {
  const layers = layersControlRef.current._layers;
  var found = false;

  layers.forEach((layer) => {
    if (map.hasLayer(layer.layer)) {
      if(layer.name === layerName) {
        found = true;
      }
    }
  })
  return found;
}