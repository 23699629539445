import React from "react";

import L from "leaflet";
import { useMap } from "react-leaflet";

import './style.css';

class MapInfo extends React.Component {
  createControl() {
    const mapInfo = L.Control.extend({
      onAdd: (map) => {
        const panelDiv = L.DomUtil.create("div", "info");

        map.addEventListener("mousemove", (ev) => {
          panelDiv.innerHTML = `
					<p class="map-info">
          <span>Lat: ${ev.latlng.lat.toFixed(5)}&nbsp;&nbsp;Lng: ${ev.latlng.lng.toFixed(5)}</span></p>`;
          //console.log(panelDiv.innerHTML);
        });
        return panelDiv;
      },
    });
    return new mapInfo({ position: this.props.position });
  }

  componentDidMount() {
    const { map } = this.props;
    const control = this.createControl();
    control.addTo(map);
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(MapInfo);
