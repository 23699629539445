import L from "leaflet";
import { formatProps } from "../helpers";

export function onEachFeature(feature, layer, layerInfo, map) {
  switch (layerInfo.geometryType) {
    case "Polygon":
      if (layerInfo.mouseover) {
        layer.on("mouseover", function (e) {
          this.setStyle({
            color: layerInfo.mouseover.color,
            opacity: 0.8,
          });
        });

        layer.on("mouseout", function (e) {
          this.setStyle({
            color: layerInfo.color,
            opacity: layerInfo.opacity,
          });
        });

        layer
          .bindTooltip(
            formatProps(
              feature.properties,
              layerInfo.mouseover.fields,
              layerInfo.mouseover.tooltipFormat
            ),
            {
              permanent: false,
              offset: [0, 0],
            }
          )
          .openTooltip();
      }
      if (layerInfo.click) {
        layer.on("click", function (e) {
          //console.log('click:', e)
          L.popup()
            .setLatLng(e.latlng)
            .setContent(
              formatProps(
                feature.properties,
                layerInfo.click.fields,
                layerInfo.click.popupFormat
              ),
              { offset: [0, 0] }
            )
            .addTo(map);
        });
      }
      break;

    case "LineString":
      if (layerInfo.mouseover) {
        layer.on("mouseover", function (e) {
          layer
            .bindTooltip(
              formatProps(
                feature.properties,
                layerInfo.mouseover.fields,
                layerInfo.mouseover.tooltipFormat
              ),
              {
                offset: [0, 0],
                sticky: true,
              }
            )
            .openTooltip();
        });
      }

      if (layerInfo.click) {
        layer.on("click", function (e) {
          const popup = L.popup()
            .setLatLng(e.latlng)
            .setContent(
              formatProps(
                feature.properties,
                layerInfo.click.fields,
                layerInfo.click.popupFormat
              ),
              {
                offset: [0, 0],
              }
            );
          popup.addTo(map);
        });
      }
      break;

    case "Point":
      if (layerInfo.mouseover) {
        layer.on("mouseover", function (e) {
          layer
            .bindTooltip(
              formatProps(
                feature.properties,
                layerInfo.mouseover.fields,
                layerInfo.mouseover.tooltipFormat
              ),
              {
                className: "label-style",
                direction: "right",
                offset: [5, 5],
              }
            )
            .openTooltip();
        });
        layer.on("mouseout", function (e) {
          this.closeTooltip();
        });
      }
      if (layerInfo.click) {
        // layer.on("click", function (e) {
        //   //console.log('click:', e)
        //   L.popup()
        //     .setLatLng(e.latlng)
        //     .setContent(
        //       formatProps(
        //         feature.properties,
        //         layerInfo.click.fields,
        //         layerInfo.click.popupFormat
        //       ),
        //       { offset: [0, 0] }
        //     )
        //     .addTo(map);
        // });

        layer.on("click", function (e) {
          layer.openPopup();
        });
      }
			if (layerInfo.labelField) {
        layer
          .bindTooltip(feature.properties[layerInfo.labelField].toString(), {
            className: "label-style",
            direction: "center",
            permanent: true,
            offset: [0, -10],
          })
          .openTooltip();
      }
      break;

    default:
      break;
  }
}
