// AutoCompleteText.js
// -------------------
// inspired by: https://www.youtube.com/watch?v=2sBDf8xbKEY
// class version

import React from "react";
import parse from "html-react-parser";
import { Form } from "react-bootstrap";
import L from "leaflet";
import { t } from "i18next";

import { formatProps, getLayerIdx } from "../../utils/helpers";
import notifyMarker from "../../utils/notifyMarker";
import "./style.css";

export default class AutoCompleteText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: "",
      item: null,
    };
    this.infoClick = this.infoClick.bind(this);
  }

  setSearchView(map, item) {
    // This function is called when a place
    // is selected from the search sidebar.

    // If the function is called because of the re-render caused by parent's state change,
    // do nothing!
    if (this.props.selected !== "search") return;

    map.setView({ lat: item.lat, lng: item.lng }, 17);

    if (map.hasLayer(highlight)) map.removeLayer(highlight);

    var highlight = L.geoJSON(null);
    map.addLayer(highlight);
    highlight.addLayer(notifyMarker(item.lat, item.lng));

    const removeHighlight = () => {
      if (map.hasLayer(highlight)) map.removeLayer(highlight);
    };

    setTimeout(removeHighlight, 2500);
  }

  onTextChanged = (e) => {
    const { searchFeatures, layers } = this.props;
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 0) {
      // check all layers -
      // searchFeatures[[],[], ..., []] := array of array of search features
      //                                   (one array for each layer)

      // create the suggestions array
      suggestions = [];

      searchFeatures.forEach((items, idx) => {
        const field = layers[idx].searchField;

        Array.prototype.push.apply(
          suggestions,
          items
            .filter(
              (item) =>
                item[field] !== null &&
                item[field].toLowerCase().indexOf(value.toLowerCase()) > -1
            )
            .map((item) => item)
        );
      });

      // suggestions.sort(compareValues('nome'))
      this.setState(() => ({ suggestions, text: value, item: null }));
    } else {
      this.setState(() => ({ suggestions, text: "", item: null }));
    }
  };

  suggestionSelected(item) {
    const { layers } = this.props;
    this.setState(() => ({
      text: item[layers[getLayerIdx(layers, item.source)].searchField],
      item: item,
      suggestions: [],
    }));
  }

  renderSuggestions() {
    const { suggestions } = this.state;
    const { layers } = this.props;

    if (suggestions.length === 0) {
      return null;
    }
    
    return (
      <ul>
        {suggestions.map((item, index) => {
          const layerIdx = getLayerIdx(this.props.initFile.jsonLayers, item.source);
          return (
            <li
              className="render-suggestions"
              onClick={() => this.suggestionSelected(item)}
              key={index}
            >
              {/* {item[layers[getLayerIdx(layers, item.source)].searchField]}
            <br /> */}
              {parse(formatProps(
                item,
                this.props.initFile.jsonLayers[layerIdx].placeFields,
                this.props.initFile.jsonLayers[layerIdx].placeFormat
              ))}
              <br /><small>
                {/* ({item[layers[getLayerIdx(layers, item.source)].searchText]})
              <br /> */}
                ({item.source})
              </small>
            </li>
          );
        })}
      </ul>
    );
  }

  infoClick(e) {
    e.stopPropagation();
    this.setSearchView(this.props.map, this.state.item);
  }

  itemInfo(layers, item) {
    var info = "";

    layers[getLayerIdx(layers, item.source)].infoFields.forEach((field, i) => {
      if (i !== 0)
        if (field !== "link")
          info += "<b>" + t(field) + ":</b>&nbsp;" + item[field] + "<br />";
        else
          info += '<a href="' + item[field] + '" target="_blank">' + item[field] + "</a><br />";
    });
    return info;
  }

  renderInfo() {
    const { item } = this.state;
    const { layers } = this.props;
    if (item !== null) {
      return (
        <>
          <ul>
            <li className="render-info" style={{backgroundColor:"rgba(211,211,211,0.6)"}}>
              <div onClick={this.infoClick} className="info-title">
                {item[layers[getLayerIdx(layers, item.source)].searchField]}
              </div>
              <small>
                {parse(this.itemInfo(layers, item))}
                <b>Layer:</b> {item.source}
                <br />
                <b>Lat:</b> {item.lat.toFixed(5)}
                <br />
                <b>Lng:</b> {item.lng.toFixed(5)}
                <br />
              </small>
            </li>
          </ul>
          {this.setSearchView(this.props.map, item)}
          {/* {this.setState({ item: null })} */}
        </>
      );
    } else {
      return null;
    }
  }

  render() {
    const { text } = this.state;
    return (
      <div className="auto-complete-text">
        <Form>
          <Form.Group>
            <Form.Control
              className="search-text"
              type="search-text"
              name="search"
              placeholder={this.props.t("Search") + "..."}
              value={text}
              onChange={this.onTextChanged}
            ></Form.Control>
          </Form.Group>
        </Form>

        {/* <input value={text} onChange={this.onTextChanged} type="text" /> */}

        {this.renderSuggestions()}
        {this.renderInfo()}
      </div>
    );
  }
}
