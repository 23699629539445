import PageHeader from "../../../main/components/PageHeader";
import UserMan from "../../components/UserMan";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const UserManPage = () => {
  useEffect(() => {
    console.log(
      "--------------------------------------UserManPage (first time)"
    );
  }, []);

  useEffect(() => {
    console.log("--------------------------------------UserManPage (again)");
  });

  const { t } = useTranslation();
  return (
    <>
      <PageHeader title={t("user_manager")} />
      <UserMan />
    </>
  );
};

export default UserManPage;
