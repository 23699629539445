import React, { useState, useContext, useEffect } from "react";
import parse from "html-react-parser";
import { t } from "i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { MapContext } from "../MapContext";
import { getBaseLayers, getKeyIdx, getLayerIdx, getOverlays } from "../../utils/helpers";

import {
  SVGCircle,
  SVGSquare,
  SVGRect,
  SVGStar,
  SVGDiamond,
} from "../../utils/symbols";

import "./style.css";

//helpers
//-------

const setLineStringImg = (layer, category) => {
  for (var i = 0, len = layer.symbols.length; i < len; i++) {
    if (layer.symbols[i].category === category) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          version="1.1"
        >
          <rect width="32" height="24" fill={layer.symbols[i].color} />
        </svg>
      );
    }
  }
};

const setPointSymbolImg = (layer, category) => {
  var symbol = null;
  var result;

  for (var i = 0, len = layer.symbols.length; i < len; i++)
    if (layer.symbols[i].category === category) {
      symbol = layer.symbols[i];
      break;
    }

  if (symbol === null) symbol = layer.symbols[0];

  switch (symbol.type) {
    case "circle":
      result = SVGCircle(
        symbol.scale,
        symbol.translateX,
        symbol.translateY,
        symbol.color,
        symbol.borderColor,
        symbol.opacity
      );
      return parse(result.svg);

    case "square":
      result = SVGSquare(
        symbol.scale,
        symbol.translateX,
        symbol.translateY,
        symbol.color,
        symbol.borderColor,
        symbol.opacity
      );
      return parse(result.svg);

    case "rect":
      result = SVGRect(
        symbol.scale,
        symbol.translateX,
        symbol.translateY,
        symbol.color,
        symbol.borderColor,
        symbol.opacity
      );
      return parse(result.svg);

    case "star":
      result = SVGStar(
        symbol.scale,
        symbol.translateX,
        symbol.translateY,
        symbol.color,
        symbol.borderColor,
        symbol.opacity
      );
      return parse(result.svg);

    case "diamond":
      result = SVGDiamond(
        symbol.scale,
        symbol.translateX,
        symbol.translateY,
        symbol.color,
        symbol.borderColor,
        symbol.opacity
      );
      return parse(result.svg);

    default:
      result = SVGCircle(
        symbol.scale,
        symbol.translateX,
        symbol.translateY,
        symbol.color,
        symbol.borderColor,
        symbol.opacity
      );
      return parse(result.svg);
  }
  // return (
  //   <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" version="1.1" >
  //     <rect width="32" height="24" fill={layer.symbols[i].color} />
  //   </svg>
  // );
};

const setIconImg = (layer, category) => {
  for (var i = 0, len = layer.icons.length; i < len; i++) {
    if (layer.icons[i].category === category) {
      return (
        <img
          src={layer.icons[i].url}
          alt=""
          width="16"
          height="20"
          style={{ verticalAlign: "middle" }}
        />
      );
    }
  }
  return "";
}

//components
//----------

function SelectionHeader({display, values, layer, layerIdx, handleCollapse, handleLayers, keyField}) {
  return (
    <div style={{display:"flex", alignItems:"center", paddingLeft:"20px"}}>
      {values
      ? <label className="btn" style={{height:"20px", width:"20px", paddingTop:"3px"}} onClick={handleCollapse}>
        <FontAwesomeIcon icon={display === "none" ? faChevronRight : faChevronDown} />
      </label> 
      : <span style={{paddingLeft: "26px"}}></span>}
      &nbsp;&nbsp;
      <input 
        id={"layers-overlays-"+layerIdx}
        className="leaflet-control-layers-selector" 
        type="checkbox" 
        onClick={(e) => {handleLayers(layer, e.target.checked)}}
      />&nbsp;
      <span style={{paddingTop:"4px", fontSize:"medium"}}>
        {layer.name} {keyField ? "("+t(keyField)+")" : ""}
      </span>
    </div>
  )
}


function SelectionValues({display, layer, values, onClick, onClickAll, filterValues, layerIdx}) {   
  return (
    <div style={{display:display, paddingLeft: "70px"}}>
      <form name={"legend-" + layer.name}>
      <div style={{paddingBottom: "5px"}} key={layer.name + ";all"}>
          <input
            type="checkbox"
            defaultChecked
            id={layer.name + ";all"}
            onClick={onClickAll}
          />
          &nbsp;&nbsp;
          <span style={{fontWeight:600}}>
            {t("select-all")}
            <br />
          </span>
        </div>

        {/* normal values */}
        {values
          ? values.map((value) => {
              return (
                <div key={layer.name + ";" + value}>
                  <input
                    type="checkbox"
                    defaultChecked
                    id={layer.name + ";" + value}
                    onClick={onClick}
                  />
                  <span>
                    &nbsp;
                    {layer.markerType === "icons"
                      ? setIconImg(layer, value)
                      : layer.markerType === "symbols"
                      ? layer.geometryType === "Point"
                        ? setPointSymbolImg(layer, value)
                        : setLineStringImg(layer, value)
                      : ""}
                    &nbsp;
                    {value}
                  </span>
                </div>
              );
            })
          : null}
      </form>
    </div>
  )
}


function MapJSONLayer({ layer, handleLayers, setJsonFilter, filterValuesChanged, setFilterValuesChanged }) {
  const { initFile, datasets, filterValues, layersControlRef } = useContext(MapContext);
  const [display, setDisplay] = useState("none");

  const layerIdx = getLayerIdx(initFile.jsonLayers, layer.name);
  const markerField = initFile.jsonLayers[layerIdx].markerField;
  const markerFieldIdx = getKeyIdx(initFile.jsonLayers[layerIdx], markerField)

  useEffect(() => {
    //console.log("MapJSONLayer-useEffect[layersControlRef]")
    if (layersControlRef.current !== null) {

      //overlays
      const overlays  = getOverlays(layersControlRef.current)
      //console.log('MapJSONLayer-overlays:', overlays)
      for (const overlay in overlays) {
        const idx = getLayerIdx(initFile.jsonLayers, overlay)
        if (idx !== -1) {
          const el = document.getElementById("layers-overlays-"+idx);
          if (el)
            overlays[overlay] ? el.setAttribute("checked", true) : el.removeAttribute("checked");
        }
      }
    }
  }, [layersControlRef.current])


  useEffect(() => {
    if(filterValuesChanged && filterValues[layerIdx][markerFieldIdx]) {
      const layerFilterValues = filterValues[layerIdx][markerFieldIdx];
      const values = datasets[layerIdx][markerFieldIdx];

      // console.log("MapJSONLayer-useEffect ->", layer.name, layerFilterValues);
      // console.log("MapJSONLayer-useEffect -> values:", values)

      const allID = layer.name+";all";
      const allElement = document.getElementById(allID);
      // console.log("MapJSONLayer-useEffect -> allElement:", allElement) ;

      if (layerFilterValues[0] === "") {
        // all elements checked, including all
        values.map((value) => {
          const inputID = layer.name+";"+value;
          const inputElement = document.getElementById(inputID);
          if (inputElement)
            inputElement.checked = true;
        })
        allElement.checked = true;
        layerFilterValues[0] = "";
      } 
      else if (layerFilterValues[0] === ">>>empty<<<") {
        // all elements unchecked
        values.map((value) => {
          const inputID = layer.name+";"+value;
          const inputElement = document.getElementById(inputID);
          if (inputElement)
            inputElement.checked = false;
        })
        allElement.checked = false;
      } 
      else {
        var allSelected = true;
        values.map((value) => {
          const inputID = layer.name+";"+value;
          const inputElement = document.getElementById(inputID);
          if (inputElement) {
            if (layerFilterValues.indexOf(value) >= 0) {
              //the value must be checked
              inputElement.checked = true;
            } else {
              //the value must be unchecked
              inputElement.checked = false;
              allSelected = false;
            }        
          }
        })
        allElement.checked = allSelected;
      }
      setFilterValuesChanged(false);
    }    
  }, [filterValuesChanged])
  
  const handleCollapse = (e) => {
    e.preventDefault();
    setDisplay(display === "none" ? "block" : "none");
  };

  const onClick = (e) => {
    const form = e.target.parentNode.parentNode;
    const elements = document.forms[form.name].getElementsByTagName("input");
    const layerName = elements[0].id.split(";")[0];

    const values = [];
    for (var i = 1, len = elements.length; i < len; i++)
      if (elements[i].checked)
        values.push(elements[i].id.split(";")[1]);

    const titleID = document.getElementById("layers-overlays-"+layerIdx);

    if (titleID && titleID.checked) {
      var allFalse = true;
      for (var i = 1, len = elements.length; i < len; i++)
        if (elements[i].checked)
          allFalse = false;

      if (allFalse) 
        values.push(">>>empty<<<")
    }

    setJsonFilter(layerName, values, markerFieldIdx);
  };

  const onClickAll = (e) => {
    const values = [];

    const form = e.target.parentNode.parentNode;
    const elements = document.forms[form.name].getElementsByTagName("input");
    const layer = elements[0].id.split(";")[0];

    if (elements[0].checked) {
      for (var i = 1, len = elements.length; i < len; i++)
        elements[i].checked = true;
    } else {
      for (var i = 1, len = elements.length; i < len; i++)
        elements[i].checked = false;
      values.push(">>>empty<<<"); // tricky setting of values to get the no features:
                                  // the '>>>empty<<<' shouldn't be used for any field
    }

    for (var i = 1, len = elements.length; i < len; i++)
      if (elements[i].checked) values.push(elements[i].id.split(";")[1]);

    setJsonFilter(layer, values, markerFieldIdx);
  };

  if (filterValues.length !== 0)
    return (
      <div>
        <SelectionHeader 
          display={display}
          values={markerFieldIdx !== -1 ? datasets[layerIdx][markerFieldIdx] : undefined}
          layer={layer}
          layerIdx={layerIdx}
          handleCollapse={handleCollapse}
          handleLayers={handleLayers}
          keyField={markerField}
        />
        <SelectionValues 
          display={display} 
          layer={layer} 
          values={datasets[layerIdx][markerFieldIdx]} 
          onClick={onClick} 
          onClickAll={onClickAll}
          filterValues={filterValues[layerIdx][markerFieldIdx]}
          keyField={markerField}
          layerIdx={layerIdx}
        />  
      </div>
    );
  else
      return null;
}


function MapBaseLayer({ layer, handleLayers }) {
  const { initFile, layersControlRef } = useContext(MapContext);

  const layerIdx = getLayerIdx(initFile.baseLayers, layer.name);
 
  useEffect(() => {
    if (layersControlRef.current !== null) {
      //base layers
      const baseLayers = getBaseLayers(layersControlRef.current)
      //('MapJSONLayer-base layers:', baseLayers)

      for (const baseLayer in baseLayers) {
        const idx = getLayerIdx(initFile.baseLayers, baseLayer)
        if (idx !== -1) {
          const el = document.getElementById("layers-baselayer-"+idx);
          baseLayers[baseLayer] ? el.setAttribute("checked", true) : el.removeAttribute("checked");
        }
      }

    }
  }, [layersControlRef.current])
  
  return (
    <>
      {/* layer heading line */}
      <div style={{display:"flex", alignItems:"center", paddingLeft:"52px"}}>
        <input 
          id={"layers-baselayer-"+layerIdx}
          className="leaflet-control-layers-selector" 
          type="checkbox" 
          onClick={(e) => {
            //console.log('onClick-checked:', e.target.checked)
            handleLayers(layer, e.target.checked)
          }}
        />&nbsp;
        <span style={{paddingTop:"4px", fontSize:"medium"}}>{layer.name}</span>
      </div>
    </>
  );
}


function MapWMSLayer({ layer, handleLayers }) {
  const { initFile, layersControlRef } = useContext(MapContext);

  const layerIdx = getLayerIdx(initFile.wmsLayers, layer.name);
 
  useEffect(() => {
    if (layersControlRef.current !== null) {
      //wms layers
      const wmsLayers = getOverlays(layersControlRef.current)
      //console.log('MapJSONLayer-WMS layers:', wmsLayers)

      for (const wmsLayer in wmsLayers) {
        const idx = getLayerIdx(initFile.wmsLayers, wmsLayer)
        if (idx !== -1) {
          const el = document.getElementById("layers-wmslayer-"+idx);
          wmsLayers[wmsLayer] ? el.setAttribute("checked", true) : el.removeAttribute("checked");
        }
      }

    }
  }, [layersControlRef.current])
  
  return (
    <>
      {/* layer heading line */}
      <div style={{display:"flex", alignItems:"center", paddingLeft:"52px"}}>
        <input 
          id={"layers-wmslayer-"+layerIdx}
          className="leaflet-control-layers-selector" 
          type="checkbox" 
          onClick={(e) => {
            //console.log('onClick-checked:', e.target.checked)
            handleLayers(layer, e.target.checked)
          }}
        />&nbsp;
        <span style={{paddingTop:"4px", fontSize:"medium"}}>{layer.name}</span>
      </div>
    </>
  );
}


function LayerSelector({type, layers, title, handleLayers, setJsonFilter, filterValuesChanged, setFilterValuesChanged }) {
  const [display, setDisplay] = useState("none");

  const handleCollapse = (e) => {
    e.preventDefault();
    setDisplay(display === "none" ? "block" : "none");
  };

  return (
    <>
      <div className="form-group top-margin-small">
        <section className="leaflet-control-layers-list">
          <div
            className="leaflet-control-layers leaflet-control layer-container"
            aria-haspopup="false"
          >
            {/* heading */}
            <div style={{display:"flex", alignItems:"center", color:"#555"}}>
              <label className="btn" style={{height:"20px", width:"20px", paddingTop:"3px"}} onClick={handleCollapse}>
                <FontAwesomeIcon icon={display === "none" ? faChevronRight : faChevronDown} />
              </label> 
              &nbsp;&nbsp;
              <span style={{paddingTop:"4px", fontWeight:"600", fontSize:"medium" }}>{title}</span>
            </div>

            {/* layers */}
            <div style={{display:display}}>
              {layers.map((layer, index) => (
                type === "JSONLayers" ?
                <MapJSONLayer 
                  key={index} 
                  layer={layer} 
                  handleLayers={handleLayers} 
                  setJsonFilter={setJsonFilter}
                  filterValuesChanged={filterValuesChanged}
                  setFilterValuesChanged={setFilterValuesChanged}
                /> :
                type === "baseLayers" ?
                <MapBaseLayer 
                  key={index}
                  layer={layer}
                  handleLayers={handleLayers}
                /> :
                type === "wmsLayers" ?
                  <MapWMSLayer 
                  key={index}
                  layer={layer}
                  handleLayers={handleLayers}
                />
                : null
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}


function MapLayers({ setJsonFilter, handleLayers, filterValuesChanged, setFilterValuesChanged }) {
  const { initFile } = useContext(MapContext);
  const [refresh, setRefresh] = useState()

  return (
    <div className="sidebar-table">
      <LayerSelector 
        layers={initFile.baseLayers}
        type="baseLayers"
        title="Base Layers" 
        handleLayers={handleLayers} 
      />
      <LayerSelector 
        layers={initFile.wmsLayers}
        type="wmsLayers"
        title="WMS Layers" 
        handleLayers={handleLayers} 
      />
      <LayerSelector 
        layers={initFile.jsonLayers}
        type="JSONLayers"
        title="JSON Layers" 
        handleLayers={handleLayers} 
        setJsonFilter={setJsonFilter}
        filterValuesChanged={filterValuesChanged}
        setFilterValuesChanged={setFilterValuesChanged}
      />
    </div>
  );
}

export default MapLayers;
