import { createContext, useState } from "react";
import { doLogin } from "../../../auth/api/doLogin";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [user, setUser] = useState({
    isLoggedIn: false,
    name: null,
    role: null,
  });

  const submitLogin = async (e, email, password) => {
    e.preventDefault();
    const response = await doLogin(email, password);
    if (response.success) {
      localStorage.setItem("token", response.token); // the jwt token!
      setUser({
        isLoggedIn: true,
        name: response.user.user_name,
        role: response.user.user_role,
      });
      //history.push("/");
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        submitLogin,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
